import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { url } from 'inspector';
import { FilterUpdate911Model, FilterRate911Model, FilterUpdateModel, FilterRate911V2Model } from 'src/Models/Update911Model';

@Injectable({
  providedIn: 'root'
})
export class Updates911Service {
  AppUrl: string = "";
  FilterUpdate911Model = new FilterUpdate911Model();
  FilterRate911Model = new FilterRate911Model();
  FilterRate911V2Model = new FilterRate911V2Model();
  filterUpdateModel = new FilterUpdateModel();
  constructor(private _http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.setAPIUrl();
  }

  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }
  UpdateAddUpdate(TokenKey: string, addNewActionModel: any): Observable<any> {
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/main911/UpdateAddUpdate';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, addNewActionModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetFund(TokenKey: string, stateId: string, isAdmin: boolean, isEdit: boolean): Observable<any> {
    this.setAPIUrl();
    this.FilterUpdate911Model = {
      pageNumber: 1,
      pageSize: 10,
      stateID: stateId,
      filterText: null,
      fundTypeID: null,
      orderBy: null,
      isAdmin: isAdmin,
      isEdit: isEdit
    }
    let data = 'api/main911/GetFund';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data, this.FilterUpdate911Model, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetRateByStateId(TokenKey: string, stateId: string, isActive: number, scopeId: string) {
    this.FilterRate911Model = {
      stateId: stateId,
      isActive: isActive,
      scopeId: scopeId
    }
    this.setAPIUrl();
    let data = 'api/main911/GetRateByStateId';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data, this.FilterRate911Model, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetAllUpdate(TokenKey: string,  PageNumber: number, PageSize: number, rateId:string,stateId:string, OrderBy:string,userId:number,isFavorite:number){
    this.filterUpdateModel = {
      pageNumber: PageNumber,
      pageSize: PageSize,
      rateId: rateId,
      stateId: stateId,
      orderBy: OrderBy,
      userId:userId,
      isFavorite:isFavorite
    }
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/main911/GetAllUpdate';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, this.filterUpdateModel, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetUpdateById(TokenKey: string, updateId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/main911/GetUpdateById/' + updateId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  DeleteUpdate(TokenKey: string, updateId: number): Observable<any> {
    this.setAPIUrl();
    let data = 'api/main911/DeleteUpdate/' + updateId;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.get<any>(this.AppUrl + data, httpOptions)
      .pipe(catchError(this.handleError));
  }

  GetAllUpdateFront(TokenKey: string,  PageNumber: number, PageSize: number, rateId:string,stateId:string, OrderBy:string,userId:number,isFavorite:number){
    this.filterUpdateModel = {
      pageNumber: PageNumber,
      pageSize: PageSize,
      rateId: rateId,
      stateId: stateId,
      orderBy: OrderBy,
      userId:userId,
      isFavorite:isFavorite
    }
    this.setAPIUrl();
    let URL = this.AppUrl + 'api/main911/GetAllUpdateFront';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(URL, this.filterUpdateModel, httpOptions)
      .pipe(catchError(this.handleError));
  }


  GetRateByStateIdV2(TokenKey: string, stateId: number, countyId: number,cityId:number, scopeId: number) {
    this.FilterRate911V2Model = {
      stateId: stateId,
      countyId: countyId,
      cityId: cityId,
      scopeId:scopeId
    }
    this.setAPIUrl();
    let data = 'api/main911/V2/GetRateByStateId';
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    return this._http.post<any>(this.AppUrl + data, this.FilterRate911V2Model, httpOptions)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.status)
  }
}


