import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MiscellaneousFeesComplianceModel } from 'src/Models/OngoingComplianceModel';
import { PricingQuotesService } from 'src/Services/pricing-quotes.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-miscellaneousfees',
  templateUrl: './miscellaneousfees.component.html',
  styleUrls: ['./miscellaneousfees.component.css']
})
export class MiscellaneousfeesComponent implements OnInit {

  isUserSystemAdmin: boolean = true;
  tokenKey: string;
  listofMiscFees: MiscellaneousFeesComplianceModel[];
  SelectedTool: string;

  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  MiscFeesTitle: string;
  isloading: boolean = false;
  isRecord: boolean = false;
  totalRecordCount: number = 0;
  isAscending: boolean = false;
  upDowTitleCSS: string = '';
  upDowAmountCSS: string = '';
  upDowNotesCSS: string = '';
  upDowLastUpdatedDateCSS: string = '';
  openMenuIndex: number = -1;

  constructor(private activedRoute: ActivatedRoute,  private router: Router, private checkAuth: CheckAuth,
    private titleService: Title, private toastr: ToastrService, private priceQuoteService: PricingQuotesService) { 
      this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
      this.listofMiscFees = [];
      this.PageNumber = 1;
      this.PageSize = 10;
      this.WhereCondition = 'null';
      this.OrderBy = 'MiscellaneousTitle';
      this.MiscFeesTitle = 'null';
      this.SelectedTool = sessionStorage.getItem('UserLoginTool');
      this.titleService.setTitle('Miscellaneous-Fees');
    }

  ngOnInit() {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.isloading = true;
    this.GetAllMiscellaneousFees();
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  addMiscFees() {
    this.router.navigate(['admin/PricingQuote/Manage/Miscellaneousfees/']);
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetAllMiscellaneousFees();
  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.MiscFeesTitle = $event.target.value;
    else
      this.MiscFeesTitle = 'null';

    this.GetAllMiscellaneousFees();
  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';

      if (sortBy === "MiscellaneousTitle") {
        this.upDowTitleCSS = 'fa fa-arrow-down';
        this.upDowAmountCSS = '';
        this.upDowNotesCSS = '';
      
      }
      else if (sortBy === "MiscellaneousAmount") {
        this.upDowTitleCSS = '';
        this.upDowAmountCSS = 'fa fa-arrow-down';
        this.upDowNotesCSS = '';
       
      }
      else if (sortBy === "MiscellaneousNote") {
        this.upDowTitleCSS = '';
        this.upDowNotesCSS = 'fa fa-arrow-down';
        this.upDowAmountCSS = '';
      
      }
      else if (sortBy === "UpdatedDate") {
        this.upDowTitleCSS = '';
        this.upDowNotesCSS = '';
        this.upDowAmountCSS = '';
        this.upDowLastUpdatedDateCSS='fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "MiscellaneousTitle") {
        this.upDowTitleCSS = 'fa fa-arrow-up';
        this.upDowAmountCSS = '';
        this.upDowNotesCSS = '';
        
      }
      else if (sortBy === "MiscellaneousAmount") {
        this.upDowTitleCSS = '';
        this.upDowAmountCSS = 'fa fa-arrow-up';
        this.upDowNotesCSS = '';
       
      }
      else if (sortBy === "MiscellaneousNote") {
        this.upDowTitleCSS = '';
        this.upDowNotesCSS = 'fa fa-arrow-up';
        this.upDowAmountCSS = '';
        
      }
      else if (sortBy === "UpdatedDate") {
        this.upDowTitleCSS = '';
        this.upDowNotesCSS = '';
        this.upDowAmountCSS = '';
        this.upDowLastUpdatedDateCSS='fa fa-arrow-up';
      }
    }
    this.GetAllMiscellaneousFees();
  }

  GetAllMiscellaneousFees() { 
      if (this.MiscFeesTitle == "null") this.MiscFeesTitle = " ";
      this.priceQuoteService.GetAllMiscellaneousFees(this.tokenKey, this.PageNumber, this.PageSize, this.MiscFeesTitle, this.OrderBy).subscribe(
        data => {
          this.isloading = false;
          this.listofMiscFees = data['miscFeesData'];
          if (this.listofMiscFees != null) {
            if (this.listofMiscFees.length == 0)
              this.isRecord = true;
            else {
              this.isRecord = false;
              this.totalRecordCount = this.listofMiscFees[0].totalRecordCount;
            }
          }
          else
            this.isRecord = true;
        },
       
      );
  }

  updateMiscFees(miscId) {
    let navigation = 'admin/PricingQuote/Manage/Miscellaneousfees/' + miscId;
    this.router.navigate([navigation]);
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }
}
