import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ToolEnum } from 'src/Models/Common';
import { FilterModel } from 'src/Models/filterModel';
import { IPCSAlertsModel } from 'src/Models/TrfaAlertsModel';
import { TrfaAlertService } from 'src/Services/alert.service';

@Component({
  selector: 'app-ipcs-alerts',
  templateUrl: './ipcs-alerts.component.html',
  styleUrls: ['./ipcs-alerts.component.css']
})
export class IPCSAlertsComponent implements OnInit {

  tokenKey: string = '';
  isloading: boolean = false;
  filteModel = new FilterModel();;
  IPCSAlertsModel: IPCSAlertsModel[];
  totalRecordCount: number = -1;
  isAscending: boolean;
  updateId: number = 0;

  upDownAlertDateCSS: string = '';
  upDownNoteCSS: string = '';
  upDownStatusCSS: string = '';
  upDownCreatedDateCSS: string = '';
  upDownModifiedDateCSS: string = '';
  Id: number = 0;
  IsSendEmail: boolean
  productId: number;
  openMenuIndex: number = -1;

  constructor(private toastr: ToastrService, private router: Router, public trfaAlertServices: TrfaAlertService,private titleService:Title) {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.productId = ToolEnum.ipcs;
    this.titleService.setTitle('IPCS Alerts');
  }

  ngOnInit() {
    this.filteModel.pageNumber = '1';
    this.filteModel.pageSize = '10';
    this.filteModel.keywordFilter = '';
    this.filteModel.productId = this.productId;
    this.filteModel.orderBy = 'UpdateId';
    this.filteModel.updateId = 0;
    this.GetIPCSAlertsFindByPages();
  }

  GetIPCSAlertsFindByPages() {
    this.isloading = true;
    if (this.filteModel.keywordFilter == "null") this.filteModel.keywordFilter = " ";
    this.trfaAlertServices.GetIPCSAlertsFindByPages(this.tokenKey, this.filteModel).subscribe(data => {
      if (data['status'] == 'Success') {
        this.isloading = false;
        this.IPCSAlertsModel = data['ipcsAlertList'];
        if (this.IPCSAlertsModel != null) {
          if (this.IPCSAlertsModel.length > 0) {
            this.totalRecordCount = +this.IPCSAlertsModel[0].totalRowCount;
          }
          else {
            this.totalRecordCount = 0;
          }
        }
      }
      else {
        this.showMessage(data['status'], 'Error', 'error');
      }

    },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }


  keywordFilters($event) {
    this.filteModel.keywordFilter = $event.target.value;
    //this.filteModel.pageNumber = '1';
    if ($event.target.value != "" && $event.target.value != null) {
      this.filteModel.keywordFilter = $event.target.value;
    }
    else { this.filteModel.keywordFilter = ''; }

    this.GetIPCSAlertsFindByPages();
  }

  sort(sortBy: any) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.filteModel.orderBy = sortBy + ' DESC';
      this.setDescCSS(sortBy);
    }
    else {
      this.isAscending = false;
      this.filteModel.orderBy = sortBy;

      this.setAscCSS(sortBy);
    }
    this.GetIPCSAlertsFindByPages();
  }

  setDescCSS(sortBy) {
    if (sortBy === "updateDate") {
      this.upDownAlertDateCSS = 'fa fa-arrow-down';
      this.upDownNoteCSS = '';
      this.upDownStatusCSS = '';
    }
    else if (sortBy === "updateCategoryName") {
      this.upDownAlertDateCSS = '';
      this.upDownNoteCSS = 'fa fa-arrow-down';
      this.upDownStatusCSS = '';
    }
    else if (sortBy === "status") {
      this.upDownAlertDateCSS = '';
      this.upDownNoteCSS = '';
      this.upDownStatusCSS = 'fa fa-arrow-down';
    }

  }

  setAscCSS(sortBy) {
    if (sortBy === "updateDate") {
      this.upDownAlertDateCSS = 'fa fa-arrow-up';
      this.upDownNoteCSS = '';
      this.upDownStatusCSS = '';
    }
    else if (sortBy === "updateCategoryName") {
      this.upDownAlertDateCSS = '';
      this.upDownNoteCSS = 'fa fa-arrow-up';
      this.upDownStatusCSS = '';
    }
    else if (sortBy === "status") {
      this.upDownAlertDateCSS = '';
      this.upDownNoteCSS = '';
      this.upDownStatusCSS = 'fa fa-arrow-up';
    }
  }

  pageChanged($event) {
    this.filteModel.pageNumber = parseInt($event.page).toString();
    this.GetIPCSAlertsFindByPages();
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }

  redirectToEditView(updateId: any) {
    this.router.navigate(['/admin/ipcs/ipcs-Alerts/user/' + updateId]);
  }

  SetCancelMailId(updateId: any) {
    this.Id = updateId;
  }

  CancelMail() {
    this.trfaAlertServices.notsentAlertmail(this.tokenKey, this.Id, this.productId).subscribe(
      data => {
        if (data['status'] == 'Success') {
          this.showMessage('', 'Success', 'success');
          this.GetIPCSAlertsFindByPages();
        }
        else
          this.showMessage(data['status'], 'Error', 'error');
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
