export enum ToolEnum {
  'wireless' = 1,
  'ipr' = 2,
  'voip' = 3,
  'actionitem' = 4,
  'generalalert' = 5,
  'inteserra360' = 6,
  'briefingServices' = 7,
  'crra' = 8,
  'trfa' = 9,
  //'intermediateProviderRegistry' = 10,
  'redLightStatus' = 11,
  'carrierTariffs' = 12,
  'commissionWebsites' = 13,
  'rateResources' = 14,
  'insite' = 15,
  'library' = 16,
  'exemptionCertificates' = 17,
  'cpcnCertificates' = 18,
  'secretaryofStatesAuthorization' = 19,
  'tarrifsnet' = 20,
  'Tax Documents' = 21,
  'RMD' = 21,
  'ratesManagementSystem' = 23,
  'inrollACP' = 22,
  'training' = 23,
  'state911' = 24,
  'exportCitiation' = 25,
  'specialReport' = 27,
  'webinar' = 28,
  'whitepapers' = 29,
  'TRFAexportCitiation' = 30,
  'inrollLifeline' = 54,
  'TRSCustomerNotice'=33,
  'View47'=36,
  'PricingQuote'=37,
  'MyFiles' = 43,
  'InteserraResources'=56,
  'CarrierFCCObligation'=57,
  'QuotesAndProposals'=38,
  'TaxExemptionForms'=40,
  'inteserraResources'=35,
  'ipcs'=41
}

export enum ActionStatusList {
  'pending' = 1,
  'notapplicable' = 2,
  'acknowledged' = 3,
  'done' = 4,
  'informativeonly' = 5
}

export enum AdminUserType {
  'systemadmin' = 1,
  'systemuser' = 2
}

export enum ActionActivity {  
  'Briefing Services' = 7,
  'Carrier Tariffs' = 11,
  'Commission Websites' = 12,   
  'Tax Documents' = 18,
  'CPCN Certificates' = 15,
  'CRRA' = 8,
  'Exemption Certificates' = 14,
  'Home' = 23,
 // 'I360' = 6,
  //'I360 Alert' = 5,  
  'IPR' = 2,
  'Local Rate Information' = 13,  
  'My Library' = 24,
  'News' = 20,  
  'Rates Management System' = 19,
  //'Red Light Status' = 10,
  'REMS' = 25,
  'Robocall Mitigation Database' = 52,   
  'Secretary of States Authorization' = 16,  
  'Special Report' = 28,
  'Tariffs.net' = 17, 
  'Trac It' = 4,  
  'TRFA' = 9,
  'VoIP' = 3,
  'Webinar Libary' = 21,
  'White Papers' = 30,   
  'Wireless' = 1,
  'Inroll ACP' = 51, //Done
  'Training' = 29,  //Done
  'State911' = 50,  //Done
  'Seminars' = 26, //Done
  'My Files' = 43, //Done
  'Company Directory' = 32, //Done
  'Change Password' = 45, //Done
  'Logout' = 53, //Done
  'Transactional Tax Login' = 41, //Done  
  'Inteserra Internal Use Only' = 44, //Done   
  'Inroll Lifeline' = 54, //Done
  'Manage Customer Resources'=55,
  'TRS Customer Notice'=33,
  'Inteserra Resources'=56,
  'View 47'=57,
  'PricingQuote'=37,
  'QuotesAndProposals'=38,
  'Tax Exemption Forms'=40

}

export const ApplicationActivityProductList: any =[  
    {id:1,itemName:'Wireless'},
    {id:2,itemName:'IPR'},
    {id:3,itemName:'VoIP'},
    {id:4,itemName:'Trac It'},  
    {id:7,itemName:'Briefing Services'},
    {id:8,itemName:'CRRA'},
    {id:9,itemName:'TRFA'},
    {id:11,itemName:'Carrier Tariffs'},
    {id:12,itemName:'Commission Websites'},  
    {id:13,itemName:'Local Rate Information'},  
    {id:14,itemName:'Exemption Certificates'},
    {id:15,itemName:'CPCN Certificates'}, 
    {id:16,itemName:'Secretary of States Authorization'},
    {id:17,itemName:'Tariffs.net'}, 
    {id:18,itemName:'Tax Documents'},
    {id:19,itemName:'Rates Management System'},
    {id:20,itemName:'News'},
    {id:21,itemName:'Webinar Libary'}, 
    {id:23,itemName:'Home'},   
    {id:24,itemName:'My Library'},    
    {id:25,itemName:'REMS'},
    {id:26,itemName:'Seminars'},
    {id:28,itemName:'Special Report'},   
    {id:29,itemName:'Training'}, 
    {id:30,itemName:'White Papers'}, 
    {id:32,itemName:'Company Directory'},
    {id:41,itemName:'Transactional Tax Login'},
    {id:43,itemName:'My Files'},
    {id:45,itemName:'Change Password'},     
    {id:50,itemName:'911'},
    {id:51,itemName:'Inroll Claims ACP'},      
    {id:52,itemName:'Robocall Mitigation Database'},   
    {id:53,itemName:'Logout'},    
    {id:54,itemName:'Inroll Claims Lifeline'},  
    {id:55,itemName:'Manage Customer Resources'},
    {id:56,itemName:'Inteserra Resources'},
    {id:33,itemName:'TRS Customer Notice'},
    {id:57,itemName:'View 47'},
    {id:38,itemName:'Quotes And Proposals'},
    {id:58,itemName:'Company Profile'},
    {id:40,itemName:'Tax Exemption Forms'},
    {id:37,itemName:'Price Quote'},
    {id:59,itemName:'IPCS'},

  ];

  export enum EnumFavorites {
    'Rate911' = 1,
    'Future911' = 2,
    'TRFAFund' = 3,
    'TRFAUpdates' = 4,
    'TRFA'=5,
    'Data911'= 6
  }
  
  export enum BaseRateType{
    'BaseRate'=1,
    'AddOn(InteserraPlatformOffering)'=2,
    'AddOn(OtherRegulatoryRequirement)'=3,
    'MiscFee'=4,
    'Compliance'=5
  }

  export enum AddOnFixPrice{
    'VoiceOnly'=1575,
    'VoiceWithBroadBandh'=2100
  }