import { Component, OnInit, ViewChild, ViewChildren, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatrixFundService } from '../../../Services/matrixfund.service';
import { CheckAuth } from '../../../Services/utility.service';
import { MatrixfundModel } from '../../../Models/matrixfundModel';
import { ToastrService } from 'ngx-toastr';
import { AppConfig } from '../../../Services/app.config';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { MatrixModel } from '../../../Models/matrixModel';
import { AssessmentcategoryModel } from '../../../Models/assessmentcategoryModel';
import { Title } from '@angular/platform-browser';
import { OtherRequirementModel } from '../../../Models/otherRequirementModel';
import { ToolEnum } from '../../../Models/Common';

@Component({
  selector: 'app-state-matrixsetup',
  templateUrl: './state-matrixsetup.component.html',
  styleUrls: ['./state-matrixsetup.component.css']
})
export class StateMatrixsetupComponent implements OnInit {
  fundForm: FormGroup;
  userID: number = 0;
  stateid: number = 0;
  statename: string = '';
  isloading: boolean = false;
  isloadingsave: boolean = false;
  tokenKey: string = '';
  matrixfundmodel: MatrixfundModel[] = [];
  totalPageSize: number = 0;
  pageSize: number = 0;
  pageIndex: number = 0;
  orderBy: string = 'null';
  keywordFilter: string = '';
  whereCondition: string = 'null';
  isAscending: boolean;
  statusCode: number = 0;
  appModel: any;
  upDowTitleCSS: string = '';
  upDowAssementCatNameCSS: string = '';
  upDowCreatedByCSS: string = '';
  upDowCreatedDateCSS: string = '';
  upDowModifiedByCSS: string = '';
  upDowModifiedDateCSS: string = '';
  matrixModel: MatrixModel = {
    matrixId: 0,
    stateId: 0,
    stateName: '',
    createdDate: null,
    createdBy: null,
    createdByName: null,
    modifiedDate: null,
    modifiedBy: null,
    modifiedByName: null
  };
  totalRecordsCount: number = -1;
  assessmentcategoryModel: AssessmentcategoryModel[] = [];
  @ViewChildren('rdfundfield') rdfundfield;
  isRecords: boolean = false;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  matrixid: number = 0;
  matrixFundId: number = 0;
  assementCatId: number = 0;
  fundFieldId: number = 0;
  // options: SortablejsOptions = {
  //   animation: 150
  // };
  // otherreqoptions: SortablejsOptions = {
  //   animation: 150
  // };
  deletematrixfundId: number = 0;
  @ViewChild('deletecloseBtn', { static: false }) deletecloseBtn: ElementRef;
  @ViewChild('assementCat', { static: false }) inputassementCat: ElementRef;
  @ViewChild('title', { static: false }) inputtitle: ElementRef;
  //otherrequirmentid: number = 0;
  //otherrequirmenttitle: string = '';
  //otherrequirmentdesc: string = '';
  otherRequirementModel: OtherRequirementModel = {
    otherRequirementId: 0,
    matrixFundId: this.matrixFundId,
    title: '',
    description: '',
    displayOrder: 0,
    createdDate: null,
    createdBy: this.userID.toString(),
    modifiedDate: null,
    modifiedBy: this.userID.toString()
  };
  @ViewChild('otherreqcloseBtn', { static: false }) otherreqcloseBtn: ElementRef;
  addotherreqinproceess: number = 0;
  deleteotherreqId: number = 0;
  SelectedTool: string;
  productId: number = 1;
  assessmentRequired: string = 'Yes';
  constructor(private _fb: FormBuilder, private MatrixFundService: MatrixFundService
    , private checkAuth: CheckAuth, private toastr: ToastrService
    , private activedRoute: ActivatedRoute, private router: Router
    , private titleService: Title
    , el: ElementRef) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userID = +sessionStorage.getItem('AdminUserId');
    this.fundForm = this._fb.group({
      matrixFundId: this.matrixFundId,
      matrixId: this.matrixid,
      title: [''],
      applicable: [''],
      currentamount: [''],
      administrativerecovery: [''],
      remit: [''],
      billtocustomer: [''],
      separatelineitem: [''],
      addinfo: [''],
      assementCatId: this.assementCatId,
      fundFieldId: this.fundFieldId,
      displayOrder: 0,
      registration: [''],
      otherRequirement: [],
      handledby: [''],
      isActive: 1,
      isDeleted: 0,
      createdBy: this.userID,
      modifiedBy: this.userID,
      assessmentRequired: this.assessmentRequired,
      ruletext:['']
    })
    this.appModel = AppConfig.settings.ConnectionStrings;
    // this.options = {
    //   onUpdate: (event: any) => {
    //     this.fundorderchange();
    //   }
    // };
    // this.otherreqoptions = {
    //   onUpdate: (event: any) => {
    //     this.otherrequirmentorderchange();
    //   }
    // };
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.productId = ToolEnum[this.SelectedTool];
    // this.assessmentRequired = 'Yes';
    this.titleService.setTitle('State Matrix Setup');
  }
  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }
  ngOnInit() {
    //this.movetotop();
    this.fundFieldId = 1;
    this.pageSize = this.appModel.PageSize;
    this.totalPageSize = 1;
    this.pageIndex = 1;
    this.keywordFilter = 'null';
    this.whereCondition = 'null';
    this.orderBy = 'DisplayOrder';
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.stateid = params['id'];
          if (params['id2']) {
            this.matrixFundId = params['id2'];
          }
          this.GetMatrixbystateid();
          this.GetAssessmentCategory();
        }
      }
    });
  }

  //Fund start
  editfund() {
    //this.matrixFundId = matrixFundId; //This from query string
    let currentfund = this.matrixfundmodel.filter((item) => item.matrixFundId == this.matrixFundId);
    this.filldata(currentfund);
    this.movetotop();
    if (this.inputtitle.nativeElement.focus()) {
      setTimeout(() => this.inputtitle.nativeElement.focus());
    } this.isloading = false;
  }
  filldata(currentfund) {
    this.matrixFundId = currentfund[0].matrixFundId;
    this.assementCatId = currentfund[0].assementCatId;
    this.fundFieldId = +currentfund[0].fundFieldId;

    this.fundForm.setValue({
      'matrixFundId': currentfund[0].matrixFundId,
      'matrixId': currentfund[0].matrixId,
      'title': currentfund[0].title,
      'applicable': currentfund[0].applicable,
      'currentamount': currentfund[0].currentAmount,
      'administrativerecovery': currentfund[0].administrativeRecovery,
      'remit': currentfund[0].remit,
      'billtocustomer': currentfund[0].billToCustomer,
      'separatelineitem': currentfund[0].separateLineItem,
      'addinfo': currentfund[0].addinfo,
      'assementCatId': currentfund[0].assementCatId,
      'fundFieldId': currentfund[0].fundFieldId,
      'displayOrder': currentfund[0].displayOrder,
      'registration': currentfund[0].registration,
      'otherRequirement': currentfund[0].listOfOtherRequirement,
      'handledby': currentfund[0].handledby,
      'isActive': 1,
      'isDeleted': 0,
      'createdBy': this.userID,
      'modifiedBy': this.userID,
      'assessmentRequired': currentfund[0].assessmentRequired,
      'ruletext': currentfund[0].ruletext,
    });
    this.assessmentRequired = currentfund[0].assessmentRequired;
    //for (let i = 0; i < this.rdfundfield._results.length; i++) {
    //  if (this.rdfundfield._results[i].nativeElement.checked) {
    //    this.fundFieldId = this.rdfundfield._results[i].nativeElement.value;
    //  }
    //}
  }

  GetMatrixbystateid(): void {
    this.isloading = true;
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.MatrixFundService.GetMatrixByStateId(this.tokenKey, this.stateid.toString(), this.userID.toString(), this.productId.toString()).subscribe(
      data => {
        if (data["status"] == "Success") {
          this.matrixModel.matrixId = data["matrix"][0].matrixId;
          this.matrixModel.stateId = data["matrix"][0].stateId;
          this.matrixModel.stateName = data["matrix"][0].stateName;
          this.matrixModel.createdDate = data["matrix"][0].createdDate;
          this.matrixModel.createdBy = data["matrix"][0].createdBy;
          this.matrixModel.createdByName = data["matrix"][0].createdByName;
          this.matrixModel.modifiedDate = data["matrix"][0].modifiedDate;
          this.matrixModel.modifiedBy = data["matrix"][0].modifiedBy;
          this.matrixModel.modifiedByName = data["matrix"][0].modifiedByName;
          this.matrixid = this.matrixModel.matrixId;
          this.statename = this.matrixModel.stateName;
          this.whereCondition = '(MatrixId = ' + this.matrixid + ')';
          this.GetMatrixFund();
        }
        else {
          this.showMessage('There is an issue while fetching data. Please try again.', 'Error', 'error');
        }
      },
      error => {
        this.statusCode = error
      },
    );
  }
  GetMatrixFund(): void {
    //this.isloading = true;
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.MatrixFundService.GetMatrixFundFilterByPage(this.tokenKey, this.pageIndex.toString(), this.pageSize.toString(), this.whereCondition, this.orderBy, this.keywordFilter, this.productId.toString()).subscribe(
      data => {
        //this.isloading = false;
        if (data["status"] == "Success") {
          this.matrixfundmodel = data["matrixFund"];
          if (this.matrixfundmodel.length > 0) {
            this.isRecords = true;
            this.totalPageSize = parseInt(this.matrixfundmodel[0].totalRecordCount);
            this.totalRecordsCount = this.matrixfundmodel.length;
            if (this.matrixFundId > 0) {
              this.editfund();
              this.fundForm.controls['assementCatId'].disable();
            }
            else {
              this.isloading = false;
            }
          }
          else {
            this.totalRecordsCount = 0;
            this.isRecords = false;
            this.isloading = false;
          }
        }
        else {
          this.showMessage('There is an issue while fetching data. Please try again.', 'Error', 'error');
        }
      },
      error => {
        this.statusCode = error
      },
    );
  }
  savefund(fundForm: any) {
    for (let i = 0; i < this.rdfundfield._results.length; i++) {
      if (this.rdfundfield._results[i].nativeElement.checked) {
        this.fundFieldId = this.rdfundfield._results[i].nativeElement.value;
      }
    }
    //if (this.assementCatId <= 0) {
    //  this.showMessage('Please select assessment.', 'Required', 'error');
    //  return;
    //}
    if ((fundForm.value["title"] == null || fundForm.value["title"] == undefined ||
      fundForm.value["title"] == "") && (this.assementCatId != 1
        && this.assementCatId != 7 && this.assementCatId != 8 && this.assementCatId != 9 && this.assementCatId != 15 && this.assementCatId != 16 && this.assementCatId != 17 && this.assementCatId != 18 && this.assementCatId != 25)) {
      this.showMessage('Please enter title.', 'Required', 'error');
      return;
    }
    if ((fundForm.value["registration"] == null || fundForm.value["registration"] == undefined ||
      fundForm.value["registration"] == "") && (this.assementCatId == 1 || this.assementCatId == 9 || this.assementCatId == 16 || this.assementCatId == 17 || this.assementCatId == 18)) {
      if (this.assementCatId == 16) {
        this.showMessage('Please enter agency regulation restricted description.', 'Required', 'error');
      }
      else if (this.assementCatId == 17 || this.assementCatId == 18) {
        this.showMessage('Please enter definition(s) description.', 'Required', 'error');
      }
      else {
        this.showMessage('Please enter registration description.', 'Required', 'error');
      }
      return;
    }
    //if ((fundForm.value["otherRequirement"] == null || fundForm.value["otherRequirement"] == undefined ||
    //  fundForm.value["otherRequirement"] == "") && this.assementCatId == 7) {
    //  this.showMessage('Please enter other requirement.', 'Required', 'error');
    //  return;
    //}
    if ((fundForm.value["handledby"] == null || fundForm.value["handledby"] == undefined ||
      fundForm.value["handledby"] == "") && this.assementCatId == 8) {
      this.showMessage('Please enter customer complaints handled by name.', 'Required', 'error');
      return;
    }
    this.blockDocument();
    if (this.assementCatId == 1 || this.assementCatId == 9) {
      fundForm.value["title"] = 'Registration';
    }
    if (this.assementCatId == 16) {
      fundForm.value["title"] = 'Regulation';
    }
    if (this.assementCatId == 17 || this.assementCatId == 18) {
      fundForm.value["title"] = 'Definition(s)';
    }
    if (this.assementCatId == 7 || this.assementCatId == 15 || this.assementCatId == 25) {
      fundForm.value["title"] = 'Other Requirements';
    }
    if (this.assementCatId == 8) {
      fundForm.value["title"] = 'Customer Complaints Handled By';
    }
    fundForm.value["matrixId"] = this.matrixid;
    fundForm.value["matrixFundId"] = this.matrixFundId;
    fundForm.value["assementCatId"] = this.assementCatId;
    fundForm.value["fundFieldId"] = this.fundFieldId;

    if (this.assementCatId == 0 || this.assementCatId == 15)
      fundForm.value["assessmentRequired"] = 'Yes';
    else
      fundForm.value["assessmentRequired"] = this.assessmentRequired;

    let msg = '';
    if (this.matrixFundId == 0) {
      msg = 'Added';
    }
    else {
      msg = 'Updated';
    }
    debugger
    this.MatrixFundService.addupdatematrixfund(this.fundForm.value, this.tokenKey).subscribe(data => {
      if (data.status == 'Success') {
        if (this.addotherreqinproceess == 1) {
          this.matrixFundId = data.planReviewerId;
          this.otherRequirementModel.matrixFundId = data.planReviewerId;
          this.saveotherRequirement();
        }
        else {
          this.nevigatetomanagesetup();
          this.showMessage(msg + ' successfully', msg, 'success');
          this.unblockDocument();
        }
      }
      else {
        if (data.message == 'duplicate') {
          this.showMessage('Duplicate assessment category not allowed.', 'Duplicate', 'error');
          this.unblockDocument();
        }
        else {
          this.showMessage(data.status, 'Error', 'error');
          this.unblockDocument();
        }
      }
    }, error => {
      this.showMessage(error, 'Error', 'error');
      this.unblockDocument();
    });
  }

  onDropSuccess() {
    this.otherrequirmentorderchange()
    // this.fundorderchange();
  }
  fundorderchange() {
    this.blockDocument();
    let _matrixfundmodel = this.matrixfundmodel;
    for (let iCounter = 0; iCounter < _matrixfundmodel.length; iCounter++) {
      _matrixfundmodel[iCounter].modifiedBy = this.userID.toString();
    }
    this.MatrixFundService.updatematrixfundDisplayOrder(this.tokenKey, this.matrixfundmodel)
      .subscribe(data => {
        if (data["status"] == "Success") {
          this.showMessage('Rearranged successfully', 'Rearranged', 'success');
          this.GetMatrixFund();
          this.isloadingsave = true;
          this.unblockDocument();
        }
        else {
          this.showMessage(data["status"], 'Error', 'error');
          this.unblockDocument();
        }
      }, error => {
        this.showMessage(error, 'Error', 'error');
        this.unblockDocument();
      })
  }

  setDeletematrixfundId(matrixfundId: any) {
    this.deletematrixfundId = matrixfundId;
  }

  DeleteFund() {
    this.blockDocument();
    this.MatrixFundService.deletefund(this.tokenKey, this.deletematrixfundId)
      .subscribe(data => {
        if (data["status"] == "Success") {
          this.showMessage('Deleted successfully', 'Deleted', 'success');
          this.deletecloseBtn.nativeElement.click();
          this.GetMatrixFund();
        }
        else {
          this.showMessage(data["status"], 'Error', 'error');
        }
        this.unblockDocument();
      }, error => {
        this.showMessage(error, 'Error', 'error');
        this.unblockDocument();
      })
  }
  ActiveInactiveFund(matrixfundId: any, isactive: any) {
    this.blockDocument();
    this.MatrixFundService.activeinactivefund(this.tokenKey, matrixfundId, isactive)
      .subscribe(data => {
        if (data["status"] == "Success") {
          if (isactive) {
            this.showMessage('Activated successfully', 'Active', 'success');
          }
          else {
            this.showMessage('Inactivated successfully', 'Inactive', 'success');
          }
          this.GetMatrixFund();
        }
        else {
          this.showMessage(data["status"], 'Error', 'error');
        }
        this.unblockDocument();
      }, error => {
        this.showMessage(error, 'Error', 'error');
        this.unblockDocument();
      })
  }
  redirectToStatePreview(stateid) {
    sessionStorage.setItem('singleStatePreview', stateid);
    let navigation = '/admin/' + this.SelectedTool + '/state/matrix/preview';
    this.router.navigate([navigation]);
  }
  //Fund end

  //Assessments -start
  GetAssessmentCategory(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.MatrixFundService.GetAssessmentCategoryAll(this.tokenKey, this.productId).subscribe(
      data => {
        if (data["status"] == "Success") {
          this.assessmentcategoryModel = data["assessmentCategory"];
        }
        else {
          this.showMessage('There is an issue while fetching data. Please try again.', 'Error', 'error');
        }
      },
      error => {
        this.statusCode = error
      },
    );
  }
  assessmentcategoryChange(event) {
    let isexists = this.matrixfundmodel.filter((item) => item.assementCatId == event.target.value);
    if (isexists.length == 0) {
      this.assementCatId = event.target.value;
      // Registration
      if (this.assementCatId == 1 || this.assementCatId == 9) {
        this.fundFieldId = 8;
        this.clearform();
      }
      if (this.assementCatId == 2 || this.assementCatId == 3 || this.assementCatId == 4 || this.assementCatId == 5 || this.assementCatId == 6
        || this.assementCatId == 10 || this.assementCatId == 11 || this.assementCatId == 12 || this.assementCatId == 13 || this.assementCatId == 14
        || this.assementCatId == 19 || this.assementCatId == 20
      ) {
        this.fundFieldId = 1;
      }
      //Other Requirement
      if (this.assementCatId == 7 || this.assementCatId == 15 || this.assementCatId == 25) {
        this.fundFieldId = 9;
        this.clearform();
      }
      //Customer Complaints Handled By
      if (this.assementCatId == 8) {
        this.fundFieldId = 0;
        this.clearform();
      }
      //Agency Regulation Restricted
      if (this.assementCatId == 16) {
        this.fundFieldId = 10;
        this.clearform();
      }
      //Definition(s)
      if (this.assementCatId == 17 || this.assementCatId == 18) {
        this.fundFieldId = 11;
        this.clearform();
      }
    }
    else {
      let cat = this.assessmentcategoryModel.filter((item) => item.assementCatId == event.target.value);
      this.fundForm.controls['assementCatId'].setValue(0);
      this.showMessage('Configuration for ' + cat[0].name + ' is already exists.', 'Duplicate', 'error');
      this.assementCatId = 0;
    }
    //if (this.matrixFundId > 0) { // Moved to category wise clear
    //  this.clearform();
    //}
  }
  //Assessments -end

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
  }
  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.orderBy = sortBy + ' DESC';

      if (sortBy === "Title") {
        this.upDowTitleCSS = 'fa fa-arrow-down';
        this.upDowAssementCatNameCSS = '';
        this.upDowCreatedByCSS = '';
        this.upDowCreatedDateCSS = '';
        this.upDowModifiedByCSS = '';
        this.upDowModifiedDateCSS = '';
      }
      else if (sortBy === "AssementCatName") {
        this.upDowTitleCSS = '';
        this.upDowAssementCatNameCSS = 'fa fa-arrow-down';
        this.upDowCreatedByCSS = '';
        this.upDowCreatedDateCSS = '';
        this.upDowModifiedByCSS = '';
        this.upDowModifiedDateCSS = '';
      }
      else if (sortBy === "CreatedBy") {
        this.upDowTitleCSS = '';
        this.upDowAssementCatNameCSS = '';
        this.upDowCreatedByCSS = 'fa fa-arrow-down';
        this.upDowCreatedDateCSS = '';
        this.upDowModifiedByCSS = '';
        this.upDowModifiedDateCSS = '';
      }
      else if (sortBy === "CreatedDate") {
        this.upDowTitleCSS = '';
        this.upDowAssementCatNameCSS = '';
        this.upDowCreatedByCSS = '';
        this.upDowCreatedDateCSS = 'fa fa-arrow-down';
        this.upDowModifiedByCSS = '';
        this.upDowModifiedDateCSS = '';
      }
      else if (sortBy === "ModifiedBy") {
        this.upDowTitleCSS = '';
        this.upDowCreatedByCSS = '';
        this.upDowAssementCatNameCSS = '';
        this.upDowCreatedDateCSS = '';
        this.upDowModifiedByCSS = 'fa fa-arrow-down';
        this.upDowModifiedDateCSS = '';
      }
      else if (sortBy === "ModifiedDate") {
        this.upDowTitleCSS = '';
        this.upDowAssementCatNameCSS = '';
        this.upDowCreatedByCSS = '';
        this.upDowCreatedDateCSS = '';
        this.upDowModifiedByCSS = '';
        this.upDowModifiedDateCSS = 'fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.orderBy = sortBy;

      if (sortBy === "Title") {
        this.upDowTitleCSS = 'fa fa-arrow-up';
        this.upDowAssementCatNameCSS = '';
        this.upDowCreatedByCSS = '';
        this.upDowCreatedDateCSS = '';
        this.upDowModifiedByCSS = '';
        this.upDowModifiedDateCSS = '';
      }
      else if (sortBy === "AssementCatName") {
        this.upDowTitleCSS = '';
        this.upDowAssementCatNameCSS = 'fa fa-arrow-up';
        this.upDowCreatedByCSS = '';
        this.upDowCreatedDateCSS = '';
        this.upDowModifiedByCSS = '';
        this.upDowModifiedDateCSS = '';
      }
      else if (sortBy === "CreatedBy") {
        this.upDowTitleCSS = '';
        this.upDowAssementCatNameCSS = '';
        this.upDowCreatedByCSS = 'fa fa-arrow-up';
        this.upDowCreatedDateCSS = '';
        this.upDowModifiedByCSS = '';
        this.upDowModifiedDateCSS = '';
      }
      else if (sortBy === "CreatedDate") {
        this.upDowTitleCSS = '';
        this.upDowAssementCatNameCSS = '';
        this.upDowCreatedByCSS = '';
        this.upDowCreatedDateCSS = 'fa fa-arrow-up';
        this.upDowModifiedByCSS = '';
        this.upDowModifiedDateCSS = '';
      }
      else if (sortBy === "ModifiedBy") {
        this.upDowTitleCSS = '';
        this.upDowAssementCatNameCSS = '';
        this.upDowCreatedByCSS = '';
        this.upDowCreatedDateCSS = '';
        this.upDowModifiedByCSS = 'fa fa-arrow-up';
        this.upDowModifiedDateCSS = '';
      }
      else if (sortBy === "ModifiedDate") {
        this.upDowTitleCSS = '';
        this.upDowAssementCatNameCSS = '';
        this.upDowCreatedByCSS = '';
        this.upDowCreatedDateCSS = '';
        this.upDowModifiedByCSS = '';
        this.upDowModifiedDateCSS = 'fa fa-arrow-up';
      }
    }
    this.GetMatrixFund();
  }
  pageChanged($event) {
    this.pageIndex = parseInt($event.page);
    this.GetMatrixFund();
  }
  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }
  cleardata() {
    this.fundForm.reset();
    this.matrixFundId = 0;
    this.assementCatId = 0;
    this.fundFieldId = 1;
    this.clearform();
  }
  clearform() {
    this.fundForm.patchValue(this.fundForm.value, { onlySelf: false, emitEvent: true });
    this.fundForm.setValue({
      'matrixFundId': this.matrixFundId,
      'matrixId': this.matrixid,
      'title': '',
      'applicable': '',
      'currentamount': '',
      'administrativerecovery': '',
      'remit': '',
      'billtocustomer': '',
      'separatelineitem': '',
      'addinfo': '',
      'assementCatId': this.assementCatId,
      'fundFieldId': this.fundFieldId,
      'displayOrder': 0,
      'registration': '',
      'otherRequirement': [],
      'handledby': '',
      'isActive': 1,
      'isDeleted': 0,
      'createdBy': this.userID,
      'modifiedBy': this.userID,
      'assessmentRequired': 'Yes',
      'ruletext':''
    });
  }
  movetotop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 1);
  }
  keywordFilters($event) {
    this.pageIndex = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.keywordFilter = $event.target.value;
    else
      this.keywordFilter = 'null';

    this.GetMatrixFund();
  }
  cancelfund() {
    this.nevigatetomanagesetup();
  }
  nevigatetomanagesetup() {
    let navigation = '/admin/' + this.SelectedTool + '/state/setup/manage/' + this.stateid;
    this.router.navigate([navigation]);
  }

  addotherreq(otherRequirementId) {
    this.otherRequirementModel.otherRequirementId = otherRequirementId;
    this.otherRequirementModel.title = '';
    this.otherRequirementModel.description = '';
  }
  editotherreq(otherRequirementId) {
    let currentfund = this.matrixfundmodel.filter((item) => item.matrixFundId == this.matrixFundId);
    let listofreq = currentfund[0].listOfOtherRequirement;
    let currentreq = listofreq.filter((item) => item.otherRequirementId == otherRequirementId);
    this.otherRequirementModel.otherRequirementId = otherRequirementId;
    this.otherRequirementModel.title = currentreq[0].title;
    this.otherRequirementModel.description = currentreq[0].description;
  }
  saveotherRequirement() {
    debugger
    if (this.matrixFundId == 0) {
      this.addotherreqinproceess = 1;
      this.savefund(this.fundForm);
      return;
    }
    if (this.otherRequirementModel.title.trim() == "") {
      this.showMessage('Please enter title.', 'Required', 'error');
      return;
    }
    if (this.otherRequirementModel.description.trim() == "") {
      this.showMessage('Please enter description.', 'Required', 'error');
      return;
    }
    this.otherRequirementModel.matrixFundId = this.matrixFundId;
    this.otherRequirementModel.createdBy = this.userID.toString();
    this.otherRequirementModel.modifiedBy = this.userID.toString();

    this.blockDocument();
    let msg = '';
    if (this.otherRequirementModel.otherRequirementId == 0) {
      msg = 'added';
    }
    else {
      msg = 'updated';
    }
    this.MatrixFundService.addupdateotherrequirment(this.otherRequirementModel, this.tokenKey).subscribe(data => {
      if (data.status == 'Success') {
        this.showMessage('Other Requirement  ' + msg + ' successfully', 'Other Requirement ' + msg, 'success');
        this.unblockDocument();
        this.GetMatrixFund();
        this.otherreqcloseBtn.nativeElement.click();
      }
      else {
        if (data.message == 'duplicate') {
          this.showMessage('Duplicate title not allowed.', 'Duplicate', 'error');
          this.unblockDocument();
        }
        else {
          this.showMessage(data.status, 'Error', 'error');
          this.unblockDocument();
        }
      }
      if (this.addotherreqinproceess == 1 && this.matrixFundId > 0) {
        this.addotherreqinproceess = 0;
        this.router.navigate(['/admin/' + this.SelectedTool + '/state/setup/edit/' + this.stateid + '/' + this.matrixFundId]);
      }
    }, error => {
      this.showMessage(error, 'Error', 'error');
      this.unblockDocument();
    });
  }
  otherrequirmentorderchange() {
    this.blockDocument();
    let _otherRequirementModel = this.fundForm.value.otherRequirement;
    this.MatrixFundService.updateotherrequirmentDisplayOrder(this.tokenKey, this.fundForm.value.otherRequirement)
      .subscribe(data => {
        if (data["status"] == "Success") {
          this.showMessage('Other requirements rearranged successfully', 'Rearranged', 'success');
          this.GetMatrixFund();
          this.unblockDocument();
        }
        else {
          this.showMessage(data["status"], 'Error', 'error');
          this.unblockDocument();
        }
      }, error => {
        this.showMessage(error, 'Error', 'error');
        this.unblockDocument();
      })
  }
  setotherreqid(deleteotherreqId) {
    this.deleteotherreqId = deleteotherreqId;
  }
  Deleteotherreq() {
    this.blockDocument();
    this.MatrixFundService.deleteotherreq(this.tokenKey, this.deleteotherreqId)
      .subscribe(data => {
        if (data["status"] == "Success") {
          this.showMessage('Deleted successfully', 'Deleted', 'success');
          this.deletecloseBtn.nativeElement.click();
          this.GetMatrixFund();
        }
        else {
          this.showMessage(data["status"], 'Error', 'error');
        }
        this.unblockDocument();
      }, error => {
        this.showMessage(error, 'Error', 'error');
        this.unblockDocument();
      })
  }

  assessmentRequiredChange(event) {
    this.assessmentRequired = event.target.value;
  }
}
