import { DatePipe } from '@angular/common';
import { Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ObligationGetLinkModel } from 'src/Models/ObligationGetLinkModel';
import { RouterExtService } from 'src/Services/RouterExtService';
import { CarrierObligationService } from 'src/Services/carrier-obligation.service';
import { CheckAuth } from 'src/Services/utility.service';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-view47-audit',
  templateUrl: './carrier-obligation-audit-v3.component.html',
  styleUrls: ['./carrier-obligation-audit-v3.component.css']
})
export class CarrierObligationAuditComponentV3 implements OnInit {
  
  ckeConfig: any;
  stateCode: any;
  userId: any;
  tokenKey: string;
  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  Note: string;
  isAscending: boolean = false;
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  totalRecordCount: number = 0;
  obligationId: number;

  listofObligation: ObligationGetLinkModel[]
  tmplistofObligation: ObligationGetLinkModel[]
  upDowsubCarriertypeCSS: string = '';
  upDowtitleCSS: string = '';
  upDowDueDateCSS: string = '';
  upDowCFRCSS: string = '';
  upDowCKlCSS:string='';
  upDowChCSS: string = '';
  upDowSubChCSS: string = '';
  upDowPartCSS: string = '';
  upDowSubPartCSS: string = '';
  upDowCFRSectionCSS: string = '';
  upDowpublishdate:string='';
  upDowlastauditdate:string='';
  listofObligation1 = []
  listofObligationExport: any=[]

  companyId:number;
  isInteserraUser: boolean = false;

  //Carrier List
  dropdownListCarrier = [];
  selectedItemsCarrier = [];
  dropdownSettingsCarrier = {};
  listOfCarrierIds = [];
  carrierId: string = ' ';

  keywordValue:string='';

  isRowEdited = false;
  //CarrierType
  dropdownSettingsCarrierFilter = {};
  dropdownCarrierFilter = [];
  selectedCarrierItems = [];
  listOfCarrierTypeIDs = [];
  carrierTypeList: any = [];
  listOfCarrierTypeName = [];

  //CategoryType
  dropdownSettingsCategoryFilter = {};
  dropdownCategoryFilter = [];
  selectedCategoryItems = [];
  listOfCategoryTypeIDs = [];
  categoryTypeList: any = [];
  listOfCategoryTypeName = [];

  openMenuIndex: number = -1;

  constructor(private router: Router, private checkAuth: CheckAuth, private carrierObligationService: CarrierObligationService
    , private titleService: Title, private toastr: ToastrService, private datePipe: DatePipe,
    private routerService: RouterExtService,private el: ElementRef, private renderer: Renderer2) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.titleService.setTitle('Pending Window');
    this.userId = +sessionStorage.getItem('AdminUserId');
    this.listofObligation = [];
    this.PageNumber = 1;
    this.PageSize = 10;
    this.WhereCondition = 'null';
    this.OrderBy = 'cfr';
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');

    sessionStorage.removeItem("callbackurl");
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {

    this.ckeConfig = {
      height: '100px', // Set the height here
      versionCheck:false,
      toolbar: [
        {
          items:
            [
              'Undo', 'Redo', 'Link', 'Unlink', 'Anchor',
              'Image', 'Table', 'SpecialChar', 'Maximize', 'Source', 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat',
              'NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Styles', 'Format', 'Font'
            ]
        }],


    }

    this.dropdownSettingsCarrier = {
      singleSelection: false,
      text: "Filter by Carrier Type",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 1,
      noDataLabel: 'No Carrier Type(s) available',
      showCheckbox: true
    };


    this.dropdownSettingsCarrierFilter = {
      singleSelection: false,
      text: "Select Carrier Type",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Carrier Type(s) available',
      showCheckbox: true
    };

    this.dropdownSettingsCategoryFilter = {
      singleSelection: false,
      text: "Select Category Type",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Category Type(s) available',
      showCheckbox: true
    };

    $(function () {
      $('.wrapper1').on('scroll', function (e) {
        $('.wrapper2').scrollLeft($('.wrapper1').scrollLeft());
      });
      $('.wrapper2').on('scroll', function (e) {
        $('.wrapper1').scrollLeft($('.wrapper2').scrollLeft());
      });
      $('.div1').width($('table').width());
      $('.div2').width($('table').width());
    });

    if(this.SelectedTool == 'view 47'){
      this.SelectedTool = 'view47';
    }

    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.getcompanyId();
    this.getCarrierType();
    this.getCategoryType();
   
    if (this.routerService.getPreviousUrl().includes('admin/view47/pending-list') || this.routerService.getPreviousUrl().includes('view47/history')) {
      this.setSaveFilter();
    }
    else {
      this.clearSession();
      this.getCarrierObligation();
    }
  }

  
  getCarrierType() {
    this.carrierObligationService.GetCarrier(this.tokenKey).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.carrierTypeList = result['carrier'];
          this.dropdownListCarrier = [];
          this.carrierTypeList.forEach(x => {
            this.dropdownListCarrier.push({ "id": x.carrierTypeId, "itemName": x.carrierName })
          });


          this.dropdownCarrierFilter = [];
          this.carrierTypeList.forEach(x => {
            this.dropdownCarrierFilter.push({ "id": x.carrierTypeId, "itemName": x.carrierName })
          });
        }
      },
      error => { }
    );
  }

  getCategoryType() {
    this.carrierObligationService.GetCategory(this.tokenKey).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.categoryTypeList = result['category'];
          this.dropdownCategoryFilter = [];
          this.categoryTypeList.forEach(x => {
            this.dropdownCategoryFilter.push({ "id": x.categoryTypeId, "itemName": x.categoryName })
          });
        }
      },
      error => { }
    );
  }

  onSelectCarrier($event) {
    this.listOfCarrierIds = this.removeElemetninArray(this.listOfCarrierIds, $event.id)
    this.listOfCarrierIds.push($event.id);
    this.carrierId = ' ';
    if (this.listOfCarrierIds.length > 0) {
      this.carrierId = this.listOfCarrierIds.join(',')
    }
    this.resetPageNoandSize();
    this.getCarrierObligation();
  }

  onDeSelectCarrier($event) {
    if (this.listOfCarrierIds != null) {
      if (this.listOfCarrierIds.length > 0) {
        this.listOfCarrierIds = this.removeElemetninArray(this.listOfCarrierIds, $event.id)
      }
    }
    if (this.listOfCarrierIds.length > 0) {
      this.carrierId = this.listOfCarrierIds.join(',')
    }
    this.resetPageNoandSize();
    this.getCarrierObligation();
  }

  onSelectAllCarrier($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCarrierIds != null) {
        if (this.listOfCarrierIds.length > 0) {
          this.listOfCarrierIds = this.removeElemetninArray(this.listOfCarrierIds, $event[iCounter].id)
          this.listOfCarrierIds.push($event[iCounter].id);
        }
        else {
          this.listOfCarrierIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfCarrierIds.length > 0) {
      this.carrierId = this.listOfCarrierIds.join(',')
    }
    this.resetPageNoandSize();
    this.getCarrierObligation();
  }

  onDeSelectAllCarrier($event) {
    this.listOfCarrierIds = [];
    this.resetPageNoandSize();
    this.getCarrierObligation();
  }

  onFilterSelectAllCarrier($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCarrierIds != null) {
        if (this.listOfCarrierIds.length > 0) {
          this.listOfCarrierIds = this.removeElemetninArray(this.listOfCarrierIds, $event[iCounter].id)
          this.listOfCarrierIds.push($event[iCounter].id);
        }
        else {
          this.listOfCarrierIds.push($event[iCounter].id);
        }
      }
    }
    if (this.listOfCarrierIds.length > 0) {
      this.carrierId = this.listOfCarrierIds.join(',')
    }
    this.resetPageNoandSize();
    this.getCarrierObligation();
  }

  onFilterDeSelectAllCarrier($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCarrierIds != null) {
        if (this.listOfCarrierIds.length > 0) {
          this.listOfCarrierIds = this.removeElemetninArray(this.listOfCarrierIds, $event[iCounter].id)
        }
      }
    }
    if (this.listOfCarrierIds.length > 0) {
      this.carrierId = this.listOfCarrierIds.join(',')
    }
    this.resetPageNoandSize();
    this.getCarrierObligation();
  }

  getcompanyId(){
    this.carrierObligationService.getCompanyIdByUserId(this.tokenKey,this.userId).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.companyId = result['companyId'];          
          if(this.companyId == 19){
            this.isInteserraUser=true;
          }
          else{
            this.isInteserraUser=false;
          }
        }
      },
      error => { }
    );
  }

  getCarrierObligation() {
    this.isloading = true;
    if (this.OrderBy == "null" || this.OrderBy == "" || this.OrderBy == "undefined")
      this.OrderBy = " ";

      this.carrierId = null;
    if (this.listOfCarrierIds.length > 0) {
      this.carrierId = this.listOfCarrierIds.join(',')
    }

    if (this.keywordValue == "null" || this.keywordValue == "" || this.keywordValue == "undefined") this.keywordValue = " ";
    this.carrierObligationService.GetAllobligation(this.tokenKey, this.PageNumber, this.PageSize, this.OrderBy,this.keywordValue,this.carrierId,false,1).subscribe(
      data => {
        this.isloading = false;
        this.listofObligation=[];
        data['obligation'].map(val => {
          let category;
          let Initial = false;
          let Maintenance = false;
          let Recurring = false;
          let Fee = false;
          let Operational = false;
          let Nottracked=false;

          if (val.categoryTypeName != null) {
           category = val.categoryTypeName.split(',');
           Initial = category.find(e => e === 'Initial')==undefined?false:true;
           Maintenance=category.find(e => e === 'Maintenance')==undefined?false:true;
           Recurring =category.find(e => e === 'Recurring')==undefined?false:true;
           Fee =category.find(e => e === 'Fee/Contribution')==undefined?false:true;
           Operational=category.find(e => e === 'Operational')==undefined?false:true;
           Nottracked=category.find(e => e === 'Not tracked')==undefined?false:true;
          }

          let Obligation: any = {
            carrierName: val.carrierTypeName,
            carrierTypeId: val.carrierTypeId,
            subCarriertype: val.subCarriertype,
            categoryName: category,
            categotyTypeId: val.categotyTypeId,
            title: val.title,
            details: val.details,
            auditornotes: val.auditornotes,
            pendingactivity: val.pendingactivity,
            dueDate: val.dueDate,

            initial: Initial,
            maintenance: Maintenance,
            recurring: Recurring,
            fee: Fee,
            operational: Operational,
            nottracked: Nottracked,

            internalnotes: val.internalnotes,
            keyterms: val.keyterms,
            relatedforfeitures: val.relatedforfeitures,
            cfr: val.cfr,
            url:val.url,

            ch:val.ch,
            subch:val.subCh,
            part:val.part,
            subpart:val.subPart,
            cfrsection:val.cfrSection,
            sortorder:val.sortOrder,
            checklist:val.checklist,

            publishdate:val.publishdate,
            lastAudit:val.lastAudit,
            obligationId: val.obligationId,
            totalRecordCount: val.totalRecordCount,
          }

          this.listofObligation.push(Obligation);
        });

        if (this.listofObligation != null) {
          if (this.listofObligation.length == 0)
            this.isRecord = true;
          else {
            this.isRecord = false;
            this.totalRecordCount = this.listofObligation[0].totalRecordCount;
          }
        }
        else
          this.isRecord = true;
      },
      error => { this.stateCode = error }
    );
  }

  addObligation() {
    let navigation = '/admin/' + this.SelectedTool + '/manage';
    sessionStorage.setItem("callbackurl","pending-list");
    this.router.navigate([navigation]);
  }

  updateObligation(obligationId) {
    this.saveSession();
    sessionStorage.setItem("callbackurl","pending-list");
    window.open('/#/admin/' + this.SelectedTool + '/manage/' + obligationId,"_blank");
  }

  editObligation(Content) {
    this.listofObligation.forEach(element => {
      element['isEdit'] = false;
    });
    this.tmplistofObligation = JSON.parse(JSON.stringify(this.listofObligation));

    Content.isEdit = true;
    
    if(Content.lastAudit!=null)
    {
      Content.lastAudit = new Date(Content.lastAudit);
    }

    this.isRowEdited = true;

      this.carrierObligationService.GetObligationById(this.tokenKey, parseInt(Content.obligationId.toString())).subscribe(
        result => {
          if (result['status'] == 'Success') {
            if (this.carrierTypeList.length > 0) {
              this.selectedCarrierItems = []
              this.listOfCarrierTypeIDs = []
              this.listOfCarrierTypeName = []
              for (let iCounter = 0; iCounter < result['obligation'].carrierTypeId.length; iCounter++) {
                let data = this.carrierTypeList.filter(x => x.carrierTypeId == result['obligation'].carrierTypeId[iCounter].carrierTypeId)[0];
                this.selectedCarrierItems.push({ "id": data.carrierTypeId, "itemName": data.carrierName })
                this.listOfCarrierTypeIDs.push(data.carrierTypeId)
                this.listOfCarrierTypeName.push(data.carrierName)
              }
            }

            if (this.categoryTypeList.length > 0) {
              this.selectedCategoryItems = []
              this.listOfCategoryTypeIDs = []
              this.listOfCategoryTypeName = []
              for (let iCounter = 0; iCounter < result['obligation'].categoryTypeId.length; iCounter++) {
                let data = this.categoryTypeList.filter(x => x.categoryTypeId == result['obligation'].categoryTypeId[iCounter].categoryTypeId)[0];
                this.selectedCategoryItems.push({ "id": data.categoryTypeId, "itemName": data.categoryName })
                this.listOfCategoryTypeIDs.push(data.categoryTypeId)
                this.listOfCategoryTypeName.push(data.categoryName)
              }
            }
            
        }
      }
      );
  }

  GetObligationById(ObligationId) {
   
  }

  cancelEdit(data) {
    data.isEdit = false;
    this.isRowEdited = false;
    this.selectedCarrierItems = [];
    this.listOfCarrierTypeIDs = [];
    this.selectedCategoryItems = [];
    this.listofObligation = this.tmplistofObligation;
  }

  setDeleteData(id) {
    this.obligationId = id;
  }

  deleteObligation(obligationId) {
    this.isloading = true;
    this.carrierObligationService.Deleteobligation(this.tokenKey, obligationId).subscribe(
      data => {
        this.isloading = false;
        if (data['status'] == 'Success') {
          this.showMessage("Obligation Deleted successfully", 'Success', 'success')
        }
        this.resetPageNoandSize();
        this.getCarrierObligation();
      },
      error => { this.stateCode = error }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  resetPageNoandSize() {
    this.PageNumber = 1;
    this.PageSize = 10;
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.getCarrierObligation();
  }

  replaceCommaLine(data) {
    if (data != null) {
      if (data.includes(',')) {
        let dataToArray = data.split(',').map(item => item.trim());
        let list = data.split(",").join("<br />")
        return list
      }
      else {
        return data;
      }
    }
  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';

      this.upDowsubCarriertypeCSS = '';
      this.upDowtitleCSS='';
      this.upDowDueDateCSS = '';     
      this.upDowCFRCSS = '';
      this.upDowChCSS = '';
      this.upDowSubChCSS = '';
      this.upDowPartCSS = '';
      this.upDowSubPartCSS = '';
      this.upDowCFRSectionCSS = '';
      this.upDowpublishdate='';
      this.upDowlastauditdate='';
      this.upDowCKlCSS = '';

      if (sortBy === "subCarriertype") {
        this.upDowsubCarriertypeCSS = 'fa fa-arrow-down';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
        this.upDowCKlCSS='';
      }
      else if (sortBy === "title") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS = 'fa fa-arrow-down';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
        this.upDowCKlCSS='';
      }
      else if (sortBy === "dueDate") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = 'fa fa-arrow-down';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
        this.upDowCKlCSS='';
      }     
      else if (sortBy === "cfr") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = 'fa fa-arrow-down';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
        this.upDowCKlCSS='';
      }
      else if (sortBy === "publishdate") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='fa fa-arrow-down';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
        this.upDowCKlCSS='';
      }
      else if (sortBy === "ch") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='fa fa-arrow-down';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
        this.upDowCKlCSS='';
      }
      else if (sortBy === "subch") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='fa fa-arrow-down';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
        this.upDowCKlCSS='';
      }
      else if (sortBy === "part") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='fa fa-arrow-down';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
        this.upDowCKlCSS='';
      }
      else if (sortBy === "subpart") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='fa fa-arrow-down';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
        this.upDowCKlCSS='';
      }
      else if (sortBy === "cfrsection") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='fa fa-arrow-down';
        this.upDowlastauditdate='';
        this.upDowCKlCSS='';
      }
      else if (sortBy === "lastaudit") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='fa fa-arrow-down';
        this.upDowCKlCSS='';
      }
      else if (sortBy === "checklist") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
        this.upDowCKlCSS='fa fa-arrow-down';
      }
    }

    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "subCarriertype") {
        this.upDowsubCarriertypeCSS = 'fa fa-arrow-up';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
        this.upDowCKlCSS='';
      }
      else if (sortBy === "title") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS = 'fa fa-arrow-up';
        this.upDowDueDateCSS = '';      
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
        this.upDowCKlCSS='';
      }
      else if (sortBy === "dueDate") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = 'fa fa-arrow-up';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
        this.upDowCKlCSS='';
      }      
      else if (sortBy === "cfr") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = 'fa fa-arrow-up';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
        this.upDowCKlCSS='';
      }
      else if (sortBy === "publishdate") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='fa fa-arrow-up';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
        this.upDowCKlCSS='';
      }
      else if (sortBy === "ch") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='fa fa-arrow-up';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
        this.upDowCKlCSS='';
      }
      else if (sortBy === "subch") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='fa fa-arrow-up';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
        this.upDowCKlCSS='';
      }
      else if (sortBy === "part") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='fa fa-arrow-up';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
        this.upDowCKlCSS='';
      }
      else if (sortBy === "subpart") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='fa fa-arrow-up';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
        this.upDowCKlCSS='';
      }
      else if (sortBy === "cfrsection") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='fa fa-arrow-up';
        this.upDowlastauditdate='';
        this.upDowCKlCSS='';
      }
      else if (sortBy === "lastaudit") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='fa fa-arrow-up';
        this.upDowCKlCSS='';
      }
      else if (sortBy === "checklist") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
        this.upDowCKlCSS='fa fa-arrow-up';
      }
    }
    this.resetPageNoandSize();
    this.getCarrierObligation();
  }

  ViewHistory(obligationId) {
    this.saveSession();
    sessionStorage.setItem("callbackurl","pending-list");
    let navigation = '/admin/' + this.SelectedTool + '/history/' + obligationId;
    this.router.navigate([navigation]);
  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.keywordValue = $event.target.value.trim();
    else
      this.keywordValue = 'null';

    this.resetPageNoandSize();
    this.getCarrierObligation();
  }


  clear(){
    this.PageNumber = 1;
    this.keywordValue = 'null';
    this.selectedItemsCarrier = [];
    this.listOfCarrierIds = [];

    this.resetPageNoandSize();
    this.getCarrierObligation();
  }


  saveSession() {
    sessionStorage.setItem('SaveFilterPageNo', this.PageNumber.toString());
    sessionStorage.setItem('SaveFilterOrderBy', this.OrderBy);
    sessionStorage.setItem('SaveFilterIsAscending', this.isAscending ? 'true' : 'false');
    sessionStorage.setItem('SaveFilterKeyword', this.keywordValue);
    sessionStorage.setItem('SaveFilterlistOfCarrierIds', JSON.stringify(this.listOfCarrierIds));
    sessionStorage.setItem('SaveFilterselectedItemsCarrier', JSON.stringify(this.selectedItemsCarrier));
  }


  clearSession() {
    sessionStorage.removeItem('SaveFilterPageNo');
    sessionStorage.removeItem('SaveFilterOrderBy');
    sessionStorage.removeItem('SaveFilterIsAscending');
    sessionStorage.removeItem('SaveFilterKeyword');
    sessionStorage.removeItem('SaveFilterlistOfCarrierIds');
    sessionStorage.removeItem('SaveFilterselectedItemsCarrier');
  }

  setSaveFilter() {
    if (sessionStorage.getItem('SaveFilterPageNo') != null && sessionStorage.getItem('SaveFilterPageNo') != '' && sessionStorage.getItem('SaveFilterPageNo') != 'undefined') {
      this.PageNumber = parseInt(sessionStorage.getItem('SaveFilterPageNo'));
    }
    if (sessionStorage.getItem('SaveFilterOrderBy') != null && sessionStorage.getItem('SaveFilterOrderBy') != '' && sessionStorage.getItem('SaveFilterOrderBy') != 'undefined') {
      this.OrderBy = sessionStorage.getItem('SaveFilterOrderBy');
    }
    if (sessionStorage.getItem('SaveFilterIsAscending') != null && sessionStorage.getItem('SaveFilterIsAscending') != '' && sessionStorage.getItem('SaveFilterIsAscending') != 'undefined') {
      this.isAscending = sessionStorage.getItem('SaveFilterIsAscending') == 'false' ? false : true;
      if (this.isAscending) {
        this.CarrierObligationsort(this.OrderBy);
      } else {
        this.CarrierObligationsort(this.OrderBy);
      }
    }
    if (sessionStorage.getItem('SaveFilterOrderBy') != null && sessionStorage.getItem('SaveFilterOrderBy') != '' && sessionStorage.getItem('SaveFilterOrderBy') != 'undefined') {
      this.OrderBy = sessionStorage.getItem('SaveFilterOrderBy');
    }
    if (sessionStorage.getItem('SaveFilterKeyword') != null && sessionStorage.getItem('SaveFilterKeyword') != '' && sessionStorage.getItem('SaveFilterKeyword') != 'undefined') {
      this.keywordValue = sessionStorage.getItem('SaveFilterKeyword');
    }
    if (sessionStorage.getItem('SaveFilterlistOfCarrierIds') != null && sessionStorage.getItem('SaveFilterlistOfCarrierIds') != '' && sessionStorage.getItem('SaveFilterlistOfCarrierIds') != 'undefined') {
      this.listOfCarrierIds = JSON.parse(sessionStorage.getItem('SaveFilterlistOfCarrierIds'));
    }
    if (sessionStorage.getItem('SaveFilterselectedItemsCarrier') != null && sessionStorage.getItem('SaveFilterselectedItemsCarrier') != '' && sessionStorage.getItem('SaveFilterselectedItemsCarrier') != 'undefined') {
      this.selectedItemsCarrier = JSON.parse(sessionStorage.getItem('SaveFilterselectedItemsCarrier'));
    }
    this.getCarrierObligation();
    this.clearSession();
  }

  CarrierObligationsort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';

      this.upDowsubCarriertypeCSS = '';
      this.upDowtitleCSS='';
      this.upDowDueDateCSS = '';     
      this.upDowCFRCSS = '';
      this.upDowChCSS = '';
      this.upDowSubChCSS = '';
      this.upDowPartCSS = '';
      this.upDowSubPartCSS = '';
      this.upDowCFRSectionCSS = '';
      this.upDowpublishdate='';
      this.upDowlastauditdate='';

      if (sortBy === "subCarriertype") {
        this.upDowsubCarriertypeCSS = 'fa fa-arrow-down';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "title") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS = 'fa fa-arrow-down';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "dueDate") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = 'fa fa-arrow-down';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }     
      else if (sortBy === "cfr") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = 'fa fa-arrow-down';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "publishdate") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='fa fa-arrow-down';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "ch") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='fa fa-arrow-down';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "subch") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='fa fa-arrow-down';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "part") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='fa fa-arrow-down';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "subpart") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='fa fa-arrow-down';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "cfrsection") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='fa fa-arrow-down';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "lastaudit") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='fa fa-arrow-down';
      }
    }

    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "subCarriertype") {
        this.upDowsubCarriertypeCSS = 'fa fa-arrow-up';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "title") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS = 'fa fa-arrow-up';
        this.upDowDueDateCSS = '';      
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "dueDate") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = 'fa fa-arrow-up';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }      
      else if (sortBy === "cfr") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = 'fa fa-arrow-up';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "publishdate") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='fa fa-arrow-up';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "ch") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='fa fa-arrow-up';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "subch") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='fa fa-arrow-up';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "part") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='fa fa-arrow-up';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "subpart") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='fa fa-arrow-up';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "cfrsection") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='fa fa-arrow-up';
        this.upDowlastauditdate='';
      }
      else if (sortBy === "lastaudit") {
        this.upDowsubCarriertypeCSS = '';
        this.upDowtitleCSS ='';
        this.upDowDueDateCSS = '';       
        this.upDowCFRCSS = '';
        this.upDowpublishdate='';
        this.upDowChCSS='';
        this.upDowSubChCSS='';
        this.upDowPartCSS='';
        this.upDowSubPartCSS='';
        this.upDowCFRSectionCSS='';
        this.upDowlastauditdate='fa fa-arrow-up';
      }
    }
  }

  saveObligation(item) {
    let obligationId = item.obligationId;
    let userId = this.userId;
    let carrierId = this.listOfCarrierTypeIDs.join(',');
    let carrierName = this.listOfCarrierTypeName.join(',');
    // let categoryId = this.listOfCategoryTypeIDs.join(',');
    // let categoryName = this.listOfCategoryTypeName.join(',');
    let title = item.title;
    let pendingactivity = item.pendingactivity;
    let auditornotes = item.auditornotes;
    let cfr = item.cfr;
    let publishdate = item.publishdate;
    let boolChk = item.checklist;
    let lastaudit = item.lastAudit;

    if (carrierId == ""  || title == "" || cfr == "" || publishdate == "") {
      if (carrierId == "") {
        this.showMessage("Please select carrier", 'warning', 'error');
      }
      if (title == "") {
        this.showMessage("Please enter title", 'warning', 'error');
      }
      if (cfr == "") {
        this.showMessage("Please enter CFR §", 'warning', 'error');
      }
      if (publishdate == "") {
        this.showMessage("Please enter published date", 'warning', 'error');
      }
    }
    else {

      if (boolChk != '' && boolChk != null) {
        if (boolChk == true) {
          boolChk = true;
        }
        else {
          boolChk = false;
        }
      }
      else {
        boolChk = false;
      }

      let value = {
        obligationId: obligationId,
        carrierTypeId: carrierId,
        title: title,
        details: '',
        pendingactivity: pendingactivity,
        auditornotes: auditornotes,
        cfr: cfr == null ? null : cfr.trim(),
        publishdate: publishdate == null ? null : publishdate.trim(),
        carrierTypeName: carrierName,
        categoryTypeName: '',
        userId: userId,
        checklist: boolChk,
        lastaudit: this.datePipe.transform(lastaudit, 'yyyy-MM-dd hh:mm:ss'),
        calltype:1
      }

      this.carrierObligationService.ObligationUpdate(this.tokenKey, value).subscribe(
        result => {
          if (result['status'] == 'Success') {
            if (result['message'] != "") {
              this.showMessage(result['message'], 'Warning', 'warning')
            } else {
              if (obligationId > 0)
                this.showMessage('Obligation updated successfully', 'Success', 'success');
              this.getCarrierObligation();
            }
          }
          else {
            this.showMessage(result['status'], 'Error', 'error');
          }
        },
        error => {
          this.showMessage(error, 'Error', 'error');
        }
      );

      item.isEdit = false;
      this.isRowEdited = false;
    }
  }

  exportCarrierObligation() {
    this.isloading = true;
    if (this.OrderBy == "null" || this.OrderBy == "" || this.OrderBy == "undefined")
      this.OrderBy = " ";

      this.carrierId = null;
    if (this.listOfCarrierIds.length > 0) {
      this.carrierId = this.listOfCarrierIds.join(',')
    }

    if (this.keywordValue == "null" || this.keywordValue == "" || this.keywordValue == "undefined") this.keywordValue = " ";
    this.carrierObligationService.GetAllobligation(this.tokenKey, this.PageNumber, this.PageSize, this.OrderBy,this.keywordValue,this.carrierId,true,1).subscribe(
      data => {
        
        this.listofObligationExport = [];
        data['obligation'].map(val => {
          let category;
          let Initial = false;
          let Maintenance = false;
          let Recurring = false;
          let Fee = false;
          let Operational = false;
          let Nottracked=false;

          if (val.categoryTypeName != null) {
           category = val.categoryTypeName.split(',');
           Initial = category.find(e => e === 'Initial')==undefined?false:true;
           Maintenance=category.find(e => e === 'Maintenance')==undefined?false:true;
           Recurring =category.find(e => e === 'Recurring')==undefined?false:true;
           Fee =category.find(e => e === 'Fee/Contribution')==undefined?false:true;
           Operational=category.find(e => e === 'Operational')==undefined?false:true;
           Nottracked=category.find(e => e === 'Not tracked')==undefined?false:true;
          }

          let Obligation: any = {
            carrierName: val.carrierTypeName,
            carrierTypeId: val.carrierTypeId,
            subCarriertype: val.subCarriertype,
            categoryName: category,
            categotyTypeId: val.categotyTypeId,
            title: val.title,
            details: val.details,
            auditornotes: val.auditornotes,
            pendingactivity: val.pendingactivity,
            dueDate: val.dueDate,

            initial: Initial,
            maintenance: Maintenance,
            recurring: Recurring,
            fee: Fee,
            operational: Operational,
            nottracked: Nottracked,

            internalnotes: val.internalnotes,
            keyterms: val.keyterms,
            relatedforfeitures: val.relatedforfeitures,
            cfr: val.cfr,
            url:val.url,

            ch:val.ch,
            subch:val.subCh,
            part:val.part,
            subpart:val.subPart,
            cfrsection:val.cfrSection,
            sortorder:val.sortOrder,
            checklist:val.checklist,

            publishdate:val.publishdate,
            lastAudit:val.lastAudit,
            obligationId: val.obligationId,
            totalRecordCount: val.totalRecordCount,
          }

          this.listofObligationExport.push(Obligation);
        });

        setTimeout(() => {
          this.exportToExcel();
        }, 1000);
        
        
      },
      error => { 
        this.isloading = false;
        this.stateCode = error 
      }
    );
  }

  

  exportToExcel() {
    this.isloading = false;
    let tableData = document.getElementById("exportobligationlist").innerHTML;

    var uri = 'data:application/vnd.ms-excel;base64,'
      , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body>{table}</body></html>'
      , base64 = function (s) { return window.btoa(unescape(encodeURIComponent(s))) }
      , format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }    
    let html = "<table><tr class='mainhead'> <td colspan='3'>";
    html += tableData;
    html += "</td></tr></table>";    
    let name = 'view47';
    var ctx = { worksheet: name || 'Worksheet', table: html }
    FileSaver.saveAs((uri + base64(format(template, ctx))), "view47.xls");
  }

  setDuplicateId(obligationId) {
    this.obligationId = obligationId;
  }

  duplicateView47Data(obligationId) {
    sessionStorage.setItem('isDuplicate', 'true');
    sessionStorage.setItem("callbackurl","pending-list");
    this.router.navigate(['admin/view47/manage/' + obligationId])
  }

  onSelectCarrierType($event) {
    this.listOfCarrierTypeIDs = this.removeElemetninArray(this.listOfCarrierTypeIDs, $event.id)
    this.listOfCarrierTypeName = this.removeElemetninArray(this.listOfCarrierTypeName, $event.itemName)
    this.listOfCarrierTypeIDs.push($event.id);
    this.listOfCarrierTypeName.push($event.itemName);
  }

  onDeSelectCarrierType($event) {

    if (this.listOfCarrierTypeIDs != null) {
      if (this.listOfCarrierTypeIDs.length > 0) {
        this.listOfCarrierTypeIDs = this.removeElemetninArray(this.listOfCarrierTypeIDs, $event.id)
      }
    }

    if (this.listOfCarrierTypeName != null) {
      if (this.listOfCarrierTypeName.length > 0) {
        this.listOfCarrierTypeName = this.removeElemetninArray(this.listOfCarrierTypeName, $event.itemName)
      }
    }
  }

  onSelectAllCarrierType($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCarrierTypeIDs != null) {
        if (this.listOfCarrierTypeIDs.length > 0) {
          this.listOfCarrierTypeIDs = this.removeElemetninArray(this.listOfCarrierTypeIDs, $event[iCounter].id)
          this.listOfCarrierTypeIDs.push($event[iCounter].id);
        }
        else {
          this.listOfCarrierTypeIDs.push($event[iCounter].id);
        }
      }

      if (this.listOfCarrierTypeName != null) {
        if (this.listOfCarrierTypeName.length > 0) {
          this.listOfCarrierTypeName = this.removeElemetninArray(this.listOfCarrierTypeName, $event[iCounter].itemName)
          this.listOfCarrierTypeName.push($event[iCounter].itemName);
        }
        else {
          this.listOfCarrierTypeName.push($event[iCounter].itemName);
        }
      }
    }
  }

  onDeSelectAllCarrierType($event) {
    this.listOfCarrierTypeIDs = [];
    this.listOfCarrierTypeName = [];
  }

  onSelectCategoryType($event) {
    this.listOfCategoryTypeIDs = this.removeElemetninArray(this.listOfCategoryTypeIDs, $event.id)
    this.listOfCategoryTypeName = this.removeElemetninArray(this.listOfCategoryTypeName, $event.itemName)
    this.listOfCategoryTypeIDs.push($event.id);
    this.listOfCategoryTypeName.push($event.itemName);
  }

  onDeSelectCategoryType($event) {

    if (this.listOfCategoryTypeIDs != null) {
      if (this.listOfCategoryTypeIDs.length > 0) {
        this.listOfCategoryTypeIDs = this.removeElemetninArray(this.listOfCategoryTypeIDs, $event.id)
      }
    }

    if (this.listOfCategoryTypeName != null) {
      if (this.listOfCategoryTypeName.length > 0) {
        this.listOfCategoryTypeName = this.removeElemetninArray(this.listOfCategoryTypeName, $event.itemName)
      }
    }
  }

  onSelectAllCategoryType($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCategoryTypeIDs != null) {
        if (this.listOfCategoryTypeIDs.length > 0) {
          this.listOfCategoryTypeIDs = this.removeElemetninArray(this.listOfCategoryTypeIDs, $event[iCounter].id)
          this.listOfCategoryTypeIDs.push($event[iCounter].id);
        }
        else {
          this.listOfCategoryTypeIDs.push($event[iCounter].id);
        }
      }

      if (this.listOfCategoryTypeName != null) {
        if (this.listOfCategoryTypeName.length > 0) {
          this.listOfCategoryTypeName = this.removeElemetninArray(this.listOfCategoryTypeName, $event[iCounter].itemName)
          this.listOfCategoryTypeName.push($event[iCounter].itemName);
        }
        else {
          this.listOfCategoryTypeName.push($event[iCounter].itemName);
        }
      }
    }
  }

  onDeSelectAllCategoryType($event) {
    this.listOfCategoryTypeIDs = [];
    this.listOfCategoryTypeName = [];
  }
  
  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }


    if (this.el.nativeElement.ownerDocument.body.classList.contains('sidebar-collapse')) {
      const tables = this.el.nativeElement.querySelectorAll('table');
      tables.forEach((table: HTMLTableElement) => {
        const thead = table.querySelector('thead');
        const tbodyRows = table.querySelectorAll('tbody tr');
        
        if (thead) {
          this.renderer.setStyle(thead, 'width', '1800px');
        }
        
        tbodyRows.forEach((row: HTMLTableRowElement) => {
          this.renderer.setStyle(row, 'display', 'table');
          this.renderer.setStyle(row, 'width', '1800px');
          this.renderer.setStyle(row, 'tableLayout', 'fixed');
        });
      });
    }
    else{
      const tables = this.el.nativeElement.querySelectorAll('table');
      tables.forEach((table: HTMLTableElement) => {
        const thead = table.querySelector('thead');
        const tbodyRows = table.querySelectorAll('tbody tr');
        
        if (thead) {
          this.renderer.setStyle(thead, 'width', '1630px');
        }
        
        tbodyRows.forEach((row: HTMLTableRowElement) => {
          this.renderer.setStyle(row, 'display', 'table');
          this.renderer.setStyle(row, 'width', '1630px');
          this.renderer.setStyle(row, 'tableLayout', 'fixed');
        });
      });
    }
  }
}


