import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ToolEnum } from 'src/Models/Common';
import { IPCSAlertsModel } from 'src/Models/TrfaAlertsModel';
import { TrfaAlertService } from 'src/Services/alert.service';
import { UsersService } from 'src/Services/users.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-manage-ipcs-alerts',
  templateUrl: './manage-ipcs-alerts.component.html',
  styleUrls: ['./manage-ipcs-alerts.component.css']
})
export class ManageIPCSAlertsComponent implements OnInit {

  tokenKey: string = '';
  userId: number = 0;
  SelectedTool: string;
  productId: number = 1;
  multiSelectDropDownHeight: number = 200;

  IPCSAlertsModel = new IPCSAlertsModel()

  addUpdateIPCSAlert = new FormGroup({
    updateId: new FormControl(''),
    updateDate: new FormControl(''),
    description: new FormControl(''),
    isNotify: new FormControl(0),
  });

  isIPCSAlerts: boolean = true;
  updateId: number = 0;
  isDataLoad: boolean = false;
  isViewMode: boolean = false;

  isNotify: boolean = true;

  pageNumber:number;
  pageSize:number;
  Email:string = '';
  Name:string = '';
  orderBy:string;
  updateCategoryName:string;
  
  constructor(private activedRoute: ActivatedRoute, private checkAuth: CheckAuth, public trfaAlertServices:TrfaAlertService,
    private userServices: UsersService, private toastr: ToastrService, private router: Router,private titleService: Title) {
      this.tokenKey = sessionStorage.getItem('tokenValue');
      this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
      this.SelectedTool = sessionStorage.getItem('UserLoginTool');
      this.productId = ToolEnum.ipcs;
      this.titleService.setTitle('Manage IPCS Alert');
    }
    ngOnInit() {
      this.pageNumber = 1;
      this.pageSize = 10;
      this.orderBy = 'updateId ASC'
      this.activedRoute.params.subscribe((params: Params) => {
  
        if (params['id']) {
          if (params['id'] != null) {
            this.isIPCSAlerts = false;
            this.updateId = params['id'];
            this.userId = params['userid'];
            this.GetIPCSAlertsByUsers();
          }
        }
        else {
          this.userId = 0;
        }
      });
    }
    tokenExpireCalculation(startDate: string, tokenExpiry: string) {
      this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
    }
    
    GetIPCSAlertsByUsers(): void {
      this.isDataLoad = true;
      this.trfaAlertServices.GetIPCSAlertsByUser(this.tokenKey, this.updateId,this.pageNumber,this.pageSize,this.userId,this.orderBy,this.productId).subscribe(
        data => {
          if (data["ipcsAlertuser"] != null && data["status"] != "Failure") {
  
            this.IPCSAlertsModel = data['ipcsAlertuser'][0];
  
            this.Email=this.IPCSAlertsModel.email;
            this.Name=this.IPCSAlertsModel.name;
            this.updateCategoryName=this.IPCSAlertsModel.updateCategoryName;
  
            if (this.IPCSAlertsModel != null) { 
              let updateDate = new Date(this.IPCSAlertsModel.updateDate);
  
              let dd1 = updateDate.getDate().toString();
              if (parseInt(dd1.toString()) < 10)
                dd1 = '0' + dd1.toString();
  
               let mm1 = (updateDate.getMonth() + 1).toString();
              if (parseInt(mm1.toString()) < 10)
                mm1 = '0' + mm1.toString();
  
              this.IPCSAlertsModel.updateDate = mm1 + '/' + dd1 + '/' + updateDate.getFullYear();
            }
  
            this.addUpdateIPCSAlert.setValue({
              updateId: this.IPCSAlertsModel.updateId,
              updateDate: this.IPCSAlertsModel.updateDate,
              description: this.IPCSAlertsModel.description,
              isNotify: this.IPCSAlertsModel.isSendMail
            });
  
            this.isDataLoad = false;
          }
          else {
            this.isDataLoad = false;
            this.showMessage(data["message"], 'Error', 'error');
          }
        }, error => {
          this.isDataLoad = false;
          this.showMessage(error, 'Error', 'error');
        })
  
    }
  
  
    showMessage(msg: string, msgTitle: string, msgType: string) {
      if (msgType == 'success') {
        setTimeout(() => this.toastr.success(msg, msgTitle));
      }
      else if (msgType == 'error') {
        setTimeout(() => this.toastr.error(msg, msgTitle));
      }
    }
  
    isAlertDate(dateArray: any) {
      if (dateArray != null) {
        let dd1 = dateArray.getDate().toString();
        if (parseInt(dd1.toString()) < 10)
          dd1 = '0' + dd1.toString();
  
        let mm1 = (dateArray.getMonth() + 1).toString();
        if (parseInt(mm1.toString()) < 10)
          mm1 = '0' + mm1.toString();
  
        this.IPCSAlertsModel.updateDate = mm1 + '/' + dd1 + '/' + dateArray.getFullYear();
      }
      else
        this.IPCSAlertsModel.updateDate = null;
    }
  
    saveAddUpdateIPCSAlert(addUpdateIPCSAlert: any) {
      if (addUpdateIPCSAlert.value['updateDate'] == '') {
        this.showMessage('Please select alert date', 'Error', 'error');
        return;
      }
      let currentDate = new Date();
      if (new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) > addUpdateIPCSAlert.value["updateDate"]) {
        this.showMessage('Previous alert date not allow', 'Error', 'error');
        return;
      }
  
      if (addUpdateIPCSAlert.value['description'] == '' || typeof (addUpdateIPCSAlert.value['description']) == 'undefined') {
        this.showMessage('Please enter description', 'Error', 'error');
        return;
      }
      if (addUpdateIPCSAlert.value['description'] != '') {
        if (addUpdateIPCSAlert.value['description'].toString().trim() == '') {
          this.showMessage('Please enter description', 'Error', 'error');
          return;
        }
      }
  
      if (typeof (addUpdateIPCSAlert.value['updateId']) == 'undefined') {
        this.IPCSAlertsModel.updateId = 0;
      }
      else {
        this.IPCSAlertsModel.updateId = addUpdateIPCSAlert.value['updateId'];
      }
  
      if (typeof (addUpdateIPCSAlert.value['description']) == 'undefined') {
        this.IPCSAlertsModel.description = '';
      }
      else {
        this.IPCSAlertsModel.description = addUpdateIPCSAlert.value['description'];
      }
      this.IPCSAlertsModel.isSendMail = this.isNotify;
      this.IPCSAlertsModel.userId = this.userId;
      this.IPCSAlertsModel.updateDate=addUpdateIPCSAlert.value['updateDate'];
      this.IPCSAlertsModel.productId=this.productId;
     
      this.trfaAlertServices.AddUpdateIPCSAlerts(this.tokenKey, this.IPCSAlertsModel).subscribe(
        data => {
          if (data['status'] == 'Success') {
            this.showMessage('IPCS alerts Updated successfully', 'Success', 'success');
            this.router.navigate(['/admin/ipcs/ipcs-Alerts']);
          }
          else
            this.showMessage(data['status'], 'Error', 'error');
        },
        error => {
          this.showMessage(error, 'Error', 'error');
        }
      );
    }
    
    removeHTMLTags(str) {
      if ((str === null) || (str === ''))
        return false;
      else
        str = str.toString();
      return str.replace(/<[^>]*>/g, '');
    }
  
    setSendEmailNotificationChecked($event) {
      this.isNotify = $event.target.checked;
    }

}
