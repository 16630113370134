import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { ReportService } from 'src/Services/report.service';
import { UpdatesService } from 'src/Services/updatesService';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-briefings-yearly-report',
  templateUrl: './briefings-yearly-report.component.html',
  styleUrls: ['./briefings-yearly-report.component.css']
})
export class BriefingsYearlyReportComponent implements OnInit {

  loginuserID: any;
  isloading = false;
  SelectedTool = '';
  tokenKey: string = '';

  dropdownListYear = [];
  selectedItemsYear = [];
  dropdownSettingsYear = {};
  listOfYearIds = [];
  selectedYear: number = 0;
  isData: boolean = false;

  selectYearBriefingsData: any = [];
  isShowprogressSpinner: boolean = false;
  blockedDocument: boolean = false;
  briefingFinalResult: any = [];
  isBrifingFinalResult: boolean = false;

  constructor(private checkAuth: CheckAuth, private titleService: Title,
    private reportService: ReportService, private updatesService: UpdatesService,
    private toastrService: ToastrService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.loginuserID = +sessionStorage.getItem('AdminUserId');
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.titleService.setTitle('Briefings Yearly Report');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');

    this.dropdownSettingsYear = {
      singleSelection: true,
      text: "Select Year",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 3,
      noDataLabel: 'No Year available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };

    this.GetYearFromBriefingMaster();
  }

  ngOnInit() {
  }

  GetYearFromBriefingMaster() {
    this.isloading = true;
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
    this.reportService.GetYearFromBriefingMaster(this.tokenKey).subscribe(data => {
      this.isloading = false;
      this.isShowprogressSpinner = false;
      this.blockedDocument = false;
      if (data) {
        if (data.status == "Success") {
          for (let iCounter = 0; iCounter < data["yearList"].length; iCounter++) {
            this.dropdownListYear.push(
              { "id": data["yearList"][iCounter].year, "itemName": data["yearList"][iCounter].year }
            );
          }
        }
      }
    })
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  onSelectYear($event) {
    this.selectedYear = $event.id;
    this.GetBriefingsDataByYear();
  }

  onDeSelectYear($event) {
    this.selectedYear = 0;
  }

  onDeSelectAllYear($event) {
    this.selectedYear = 0;
    this.selectYearBriefingsData=[];
    this.briefingFinalResult =[];
    this.isData = false;
    this.isBrifingFinalResult = false;
  }

  GetBriefingsDataByYear() {
    if (this.selectedYear != 0) {
      this.isloading = true;
      this.isShowprogressSpinner = true;
      this.blockedDocument = true;
      this.reportService.GetBriefingsDataByYear(this.tokenKey, this.selectedYear).subscribe(data => {
        this.isloading = false;
        this.isShowprogressSpinner = false;
        this.blockedDocument = false;
        if (data) {
          if (data.status == "Success") {
            this.selectYearBriefingsData = data["briefingsData"];
            this.briefingFinalResult = data["briefingFinalResult"];
            if (this.selectYearBriefingsData.length > 0)
              this.isData = true;
            else
              this.isData = false;

            if (this.briefingFinalResult.length > 0)
              this.isBrifingFinalResult = true;
            else
              this.isBrifingFinalResult = false;
          }
        }
      })
    }
  }

}
