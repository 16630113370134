import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UsersService } from '../../../Services/users.service';
import { CheckAuth } from '../../../Services/utility.service';
import { StateMasterService } from '../../../Services/state-master.service';
import { UpdatesService } from '../../../Services/updatesService';
import { IprUpdatesService } from '../../../Services/iprupdatesService';
import { ToolEnum, AdminUserType } from '../../../Models/Common';
import { ActionItemService } from '../../../Services/actionitem.service';
import { ActionDashboardCountModel } from '../../../Models/ActionDashboardCountModel';
import { Inteserrai360DashboardCountModel } from 'src/Models/Inteserrai360DashboardCountModel';
import { I360Service } from 'src/Services/i360Service';
import { FilterModel, SysFilter } from 'src/Models/filterModel';
import { MenuService } from 'src/Services/menu.service';
import { RmdupdatesService } from 'src/Services/rmdupdates.service';
import { BriefingDashboardCount } from 'src/Models/BriefingDashboardCountModel';
import { CountyMasterService } from 'src/Services/county-master.service';
import { CityMasterService } from 'src/Services/city-master.service';
import { Main911Service } from 'src/Services/main911.service';
import { TrfaCategoryMasterService } from 'src/Services/trfa-category-master.service';
import { TrfaCarrierMasterService } from 'src/Services/trfa-carrier--master.service';
import { TrfaFundTypeMasterService } from 'src/Services/trfa-fund-type--master.service';
import { TrfaFundMasterService } from 'src/Services/trfa-fund-master.service';
import { TrfaUpdateMasterService } from 'src/Services/trfa-update--master.service';
import { TrfaContentMasterService } from 'src/Services/trfa-content-master.service';
import { TrfaLinkMasterService } from 'src/Services/trfa-link-master.service';
import { TrfaSourceMasterService } from 'src/Services/trfa-source-master.service';
import { TRFADashboardService } from 'src/Services/trfa-dashboard.service';
import { TRFARecordsCountModel } from 'src/Models/TRFARecordsCountModel';
import { I911DashboardService } from 'src/Services/i911-dashboard.service';
import { Updates911Service } from 'src/Services/updates911.service';
import { TrfaAlertService } from 'src/Services/alert.service';
import { CrraCarrierMasterService } from 'src/Services/crra-carrier-master.service';
import { CrraCategoryMasterService } from 'src/Services/crra-category-master.service';
import { CrraSubCategoryMasterService } from 'src/Services/crra-sub-category-master.service';
import { CrraUpdatesMasterService } from 'src/Services/crra-updates-master.service';
import { CrraDepositeMasterService } from 'src/Services/crra-deposite-master.service';
import { CrraContentMasterService } from 'src/Services/crra-content-master.service';
import { CrraLinkMasterService } from 'src/Services/crra-link-master.service';
import { CrraDashboardService } from 'src/Models/crra-dashboard.service';
import { PricingQuotingDashboardService } from 'src/Services/pricing-quoting-dashboard.service';
import { CarrierObligationService } from 'src/Services/carrier-obligation.service';
import { CategoryObligationService } from 'src/Services/category-obligation.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  tokenKey: string = '';
  pageSize: string = '100000';
  totalusers: number = 0;
  totalclients: number = 0;
  totalCompanies: number = 0;
  totalMenus: number = 0;
  totalstates: number = 0;
  totalupdates: number = 0;
  totalIPRupdates: number = 0;
  totalRMDupdates: number = 0;
  SelectedTool: string;
  productId: number = 1;
  actionCount = new ActionDashboardCountModel();
  i360DashboardCountModel = new Inteserrai360DashboardCountModel();
  briefingDashboardCount = new BriefingDashboardCount();
  totalCounty: number = 0;
  totalCity: number = 0;
  is911TRfa: boolean = false;

  isUserSystemAdmin: boolean = false;
  loginUserId: number = 0;
  systemUserCondition: string = '';
  sysFilter = new SysFilter();
  total911Main: number = 0;
  totalTrfaCategory: number = 0;
  totalTrfaCarrier: number = 0;
  totalTrfaFundType: number = 0;
  totalTrfaFund: number = 0;
  totalTrfaUpdate: number = 0;
  module: string;
  totalInactive911Main: number = 0;
  totalContacts911Main: number = 0;
  totalContact911Main: number = 0;
  totalContentTrfa: number = 0;
  totalLinkTrfa: number = 0;
  totalSource: number = 0;
  totalAuditCount: number = 0;
  total911RecordCount = 0;
  totalRecordCountofType = 0;
  totalRecordCountofSubType = 0;
  listofRecordCount: TRFARecordsCountModel[];

  filteModel = new FilterModel();
  totalTracItAlerts = 0;
  totalTrfaAlerts = 0;
  total911Alerts = 0;
  totalVoipAlerts = 0;
  totalWirelessAlerts = 0;
  totalBriefingAlerts = 0;
  totalIprAlerts = 0;
  totalRMDAlerts = 0;
  totalcrrastates: number = 0;
  totalCRRACarrier: number = 0;
  totalCRRACategory: number = 0;
  totalCRRASubCategory: number = 0;
  totalCRRAUpdates: number = 0;
  totalCRRADeposite: number = 0;
  totalCRRAContent: number = 0;
  totalCRRACompareCount: number = 0;
  totalCRRALink: number = 0;
  totalCRRAAlerts: number = 0;

  totalBaseRateType:number=0;
  totalBaseRate:number=0;
  totalPricingQuotes:number=0 
  miscFeesCount:number=0
  clientInformationCount:number=0
  onboardingCount:number=0

  totalAlerts: number = 0;
  allactivesubscriberscounts: number = 0;
  totalObligationUpdateCount: number = 0;
  totalObligationCarrierCount: number = 0;
  totalObligationCategoryCount: number = 0;
  totalObligationContentCount: number = 0;
  totalObligationAuditCount: number = 0;
  totalInternalCount:number = 0;


  constructor(private actionService: ActionItemService, private titleService: Title, private userService: UsersService
    , private checkAuth: CheckAuth, private state: StateMasterService, private menuService: MenuService,
    private i360Service: I360Service,
    private countyService: CountyMasterService,
    private cityService: CityMasterService,
    private trfaContentMaster: TrfaContentMasterService,
    private linkTypeService: TrfaLinkMasterService,
    private main911Service: Main911Service, private updatesService: UpdatesService, private iprupdatesService: IprUpdatesService,
    private rmdupdatesService: RmdupdatesService,
    private trfaCategoryMaster: TrfaCategoryMasterService, private trfaCarrierMaster: TrfaCarrierMasterService,
    private trfaFundTypeMaster: TrfaFundTypeMasterService, private trfaFundMaster: TrfaFundMasterService,
    private trfaUpdateMaster: TrfaUpdateMasterService, private trfaSourceMaster: TrfaSourceMasterService, private updates911: Updates911Service,
    private trfaDashboardService: TRFADashboardService, private i911DashboardService: I911DashboardService, private trfaAlertServices: TrfaAlertService,
    private crraCarrierMaster:CrraCarrierMasterService,private crraCategoryMaster:CrraCategoryMasterService,private crraSubCategoryMaster:CrraSubCategoryMasterService,
    private crraUpdateMaster:CrraUpdatesMasterService,private crraDepositeMaster:CrraDepositeMasterService,private crraContentMaster:CrraContentMasterService,
    private crraLinkMaster:CrraLinkMasterService,private crraDashboardService: CrraDashboardService,private pricingQuoteDashboardService: PricingQuotingDashboardService, private carrierObligationService: CarrierObligationService,
    private categoryObligationService: CategoryObligationService) {

    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.productId = ToolEnum[this.SelectedTool];
    this.titleService.setTitle('Dashboard');

    this.loginUserId = +sessionStorage.getItem('AdminUserId');
  }

  ngOnInit() {
    if (this.productId == ToolEnum.trfa || this.SelectedTool == '911') {
      this.is911TRfa = true;
    }

    if (this.SelectedTool == 'Admin') {
      if (AdminUserType.systemadmin == parseInt(sessionStorage.getItem('adminTypeId'))) {
        this.isUserSystemAdmin = true;
        this.GetListOfUser(3, ' UserTypeId = 3'); // Get Total Subscribers
      }
      else
        this.GetListOfUser(3, '  (UserTypeId = 3 And CompanyId IN (Select CompanyId From [SystemUserCompany] Where [SystemUserCompany].UserId = ' + this.loginUserId + '))'); // Get Total Subscribers
      
        this.GetAdminDashboardCount();
    }

    else if (this.productId == ToolEnum.inteserra360) {
      this.Geti360DashboardCount();
    }

    else if (this.productId == ToolEnum.briefingServices) {
      this.GetBriefingDashboardCount();
    }

    else if (this.productId == ToolEnum.ipr) {
      this.GetIPRDashboardCount();
    }

    else if (this.SelectedTool == 'rmd') {
      this.GetRMDDashboardCount();
    }

    else if (this.productId == ToolEnum.actionitem) {
      this.GetActionDashboardCount();
    }

    else if (this.productId == ToolEnum.voip || this.productId == ToolEnum.wireless || this.productId == ToolEnum.ipcs) {
      if (this.productId == ToolEnum.voip)
        this.productId = ToolEnum.voip;
      else if (this.productId == ToolEnum.wireless)
        this.productId = ToolEnum.wireless;
      else if (this.productId == ToolEnum.ipcs)
        this.productId = ToolEnum.ipcs;
      this.GetWirelessVoIPRecordsCountForAllPage(this.productId)
    }

    else if (this.SelectedTool == '911') {

      this.Get911RecordsCountForAllPage();
    }

    else if (this.SelectedTool == 'trfa') {

      this.GetTRFARecordsCountForAllPage();
    }

    else if (this.SelectedTool == 'crra') {
      this.GetCRRARecordsCountForAllPage();
    }
    if(this.SelectedTool == 'PricingQuote'){

      this.GetPricingQuotesRecordsCountForAllPage();
    }

    if (this.SelectedTool == 'view 47') {
      this.GetView47DashboardCount();
    }
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  Get911RecordsCountForAllPage() {
    if (this.tokenKey != null) {
      this.i911DashboardService.GetRecordsCountForAllPage(this.tokenKey).subscribe(
        data => {
          this.listofRecordCount = data['recordCount'];
          if (this.listofRecordCount != null) {
            if (this.listofRecordCount.length != 0) {
              this.totalstates = this.listofRecordCount[0]["stateCount"];
              this.totalCounty = this.listofRecordCount[0]["countyCount"];
              this.totalCity = this.listofRecordCount[0]["cityCount"];
              this.total911Main = this.listofRecordCount[0]["rateCount"];
              this.totalContacts911Main = this.listofRecordCount[0]["contactCount"];
              this.total911RecordCount = this.listofRecordCount[0]["updateCount"];
              this.total911Alerts = this.listofRecordCount[0]["alert911Count"];
            }
          }
        },
        error => { }
      );
    }
  }

  GetTRFARecordsCountForAllPage() {
    if (this.tokenKey != null) {
      this.trfaDashboardService.GetRecordsCountForAllPage(this.tokenKey).subscribe(
        data => {
          this.listofRecordCount = data['recordCount'];
          if (this.listofRecordCount != null) {
            if (this.listofRecordCount.length != 0) {
              this.totalstates = this.listofRecordCount[0]["stateCount"];
              this.totalTrfaCategory = this.listofRecordCount[0]["categoriesCount"];
              this.totalTrfaCarrier = this.listofRecordCount[0]["carrierCount"];
              this.totalTrfaFundType = this.listofRecordCount[0]["fundTypeCount"];
              this.totalTrfaFund = this.listofRecordCount[0]["fundsCount"];
              this.totalTrfaUpdate = this.listofRecordCount[0]["updatesCount"];
              this.totalContentTrfa = this.listofRecordCount[0]["contentCount"];
              this.totalLinkTrfa = this.listofRecordCount[0]["linksCount"];
              this.totalSource = this.listofRecordCount[0]["sourceCount"];
              this.totalAuditCount = this.listofRecordCount[0]["auditCount"];
              this.totalTrfaAlerts = this.listofRecordCount[0]["trfaAlertCount"];
            }
          }
        },
        error => { }
      );
    }
  }

  GetPricingQuotesRecordsCountForAllPage() {
    if (this.tokenKey != null) {
      this.pricingQuoteDashboardService.GetRecordsCountForAllPage(this.tokenKey).subscribe(
        data => {
          this.listofRecordCount = data['recordCount'];
          if (this.listofRecordCount != null) {
            if (this.listofRecordCount.length != 0) {
              this.totalBaseRateType = this.listofRecordCount[0]["baseRateType"];
              this.totalBaseRate = this.listofRecordCount[0]["baseRate"];
              this.totalPricingQuotes = this.listofRecordCount[0]["pricingQuotes"];
              this.miscFeesCount = this.listofRecordCount[0]["miscFeesCount"];
              this.clientInformationCount = this.listofRecordCount[0]["clientInformationCount"];
              this.onboardingCount = this.listofRecordCount[0]["onboardingCount"];
            }
          }
        },
        error => { }
      );
    }
  }

  GetListOfUser(usertype, WhereCondition) {
    if (this.tokenKey != null) {
      this.userService.GetUsers(this.tokenKey, 1, this.pageSize.toString(), WhereCondition, 'UserId DESC', 'null', this.loginUserId, "", "", "","").subscribe(
        data => {
          if (usertype == 2) {
            this.totalusers = data['usersModel'].length;
          }
          if (usertype == 3) {
            this.totalclients = data['usersModel'].length;
          }
        }, error => { }
      );
    }
  }


  getCompanies() {
    this.userService.GetCompanyListWithIds(this.tokenKey).subscribe(data => {
      if (data['status'] !== 'Failure') {
        this.totalCompanies = data['companyModel'].length;
      }
    })
  }

  getMenus() {
    this.menuService.i360MenuItems(this.tokenKey, 0).subscribe(data => {
      if (data['status'] !== 'Failure') {
        this.totalMenus = data['listOfMenu'].length;
      }
    })
  }

  GetState() {
    if (this.SelectedTool == '911' || this.SelectedTool == 'trfa') {
      if (this.SelectedTool == '911') this.module = '911';
      else this.module = 'TRFA';
      this.state.GetStateMaster(this.tokenKey, this.module).subscribe(
        data => {
          this.totalstates = data['state'].length;
        },
        error => { }
      );
    } else {
      this.state.GetStateMasterFindByPaged(this.tokenKey, 1, this.pageSize.toString(), 'null', 'DisplayOrder,Name', 'null', this.productId).subscribe(
        data => {
          this.totalstates = data['state'].length;
        },
        error => { }
      );
    }
  }
  GetUpdates(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    if (AdminUserType.systemadmin == parseInt(sessionStorage.getItem('adminTypeId')))
      this.systemUserCondition = 'null'
    else
      this.systemUserCondition = '  (ISNULL(CompanyId,0) = 0 Or CompanyId IN (Select CompanyId From [SystemUserCompany] Where [SystemUserCompany].UserId = ' + this.loginUserId + '))';

    this.updatesService.GetUpdatesFilterByPage(this.tokenKey, '1', this.pageSize.toString(), 'null', 'DisplayOrder', 'null', this.productId, false, this.systemUserCondition).subscribe(
      data => {
        if (data["status"] == "Success") {
          this.totalupdates = data["updates"].length;
        }
      },
      error => { },
    );
  }

  GetIPRUpdates(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.iprupdatesService.GetUpdatesFilterByPage(this.tokenKey, '1', '5', '', 'ISNULL(ModifiedDate,CreatedDate) DESC', '').subscribe(
      data => {
        if (data["status"] == "Success") {
          if (data["updates"].length > 0) {
            this.totalIPRupdates = parseInt(data["updates"][0].totalRecordCount)
          }
        }

      },
      error => { },
    );
  }
  GetRMDUpdates(): void {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    const filter = new FilterModel();
    filter.pageNumber = '1';
    filter.pageSize = '5';
    filter.orderBy = 'Sys_updated_on DESC';
    this.rmdupdatesService.GetUpdatesFilterByPage(this.tokenKey, filter).subscribe(
      data => {
        if (data["status"] == "Success") {
          if (data["updates"].length > 0) {
            this.totalRMDupdates = parseInt(data["updates"][0].totalRecordCount)
          }
        }

      },
      error => { },
    );
  }

  GetActionDashboardCount() {
    this.sysFilter.systemUserCondition = this.systemUserCondition;
    this.actionService.GetAdminActionItemDashboardCounts(this.tokenKey, this.sysFilter).subscribe(
      data => {
        if (data['status'] == 'Success')
          this.actionCount = data['counts'];
      }, error => { }
    );
  }

  Geti360DashboardCount() {
    this.i360Service.i360DashboardCount(this.tokenKey).subscribe(
      data => {
        if (data['status'] == 'Success')
          this.i360DashboardCountModel = data['i360DashboardCount'];
      }, error => { }
    );
  }

  GetBriefingDashboardCount() {
    this.i360Service.GetBriefingDashboardCount(this.tokenKey).subscribe(
      data => {
        if (data['status'] == 'Success')
          this.briefingDashboardCount = data['briefingDashboardCount'];
      }, error => { }
    );
  }

  // 911
  GetAllCounty() {
    this.countyService.GetAllCounty(this.tokenKey, 1, 10, " ", " ", 'CountyName').subscribe(
      data => {
        if (data['county'].length > 0)
          this.totalCounty = data['county'][0].totalRecordCount;
        else this.totalCounty = 0;
      },
      error => { }
    );
  }
  GetAllCity() {
    this.cityService.GetAllCity(this.tokenKey, 1, 10, " ", " ", " ", 'CityName').subscribe(
      data => {
        if (data['city'].length > 0)
          this.totalCity = data['city'][0].totalRecordCount;
        else this.totalCity = 0;
      },
      error => { }
    );
  }
  MainGetAll() {
    this.main911Service.MainGetAll(this.tokenKey, 1, 10, " ", " ", " ", 'TaxName', '1').subscribe(
      data => {
        if (data['main'].length > 0)
          this.total911Main = data['main'][0].totalRecordCount;
        else this.total911Main = 0;
      },
      error => { }
    );
  }
  GetAllCategory() {
    this.trfaCategoryMaster.GetAllCategory(this.tokenKey, 1, 10, " ", 'CategoryName').subscribe(
      data => {
        if (data['category'].length > 0)
          this.totalTrfaCategory = data['category'][0].totalRecordCount;
        else this.totalTrfaCategory = 0;
      },
      error => { }
    );
  }
  GetAllCarrier() {
    this.trfaCarrierMaster.GetAllCarrier(this.tokenKey, 1, 10, " ", 'CarrierName').subscribe(
      data => {
        if (data['carrier'].length > 0)
          this.totalTrfaCarrier = data['carrier'][0].totalRecordCount;
        else this.totalTrfaCarrier = 0;
      },
      error => { }
    );
  }
  GetAllFundType() {
    this.trfaFundTypeMaster.GetAllFundType(this.tokenKey, 1, 10, " ", 'FundTypeName').subscribe(
      data => {
        if (data['fundType'].length > 0)
          this.totalTrfaFundType = data['fundType'][0].totalRecordCount;
        else this.totalTrfaFundType = 0;
      },
      error => { }
    );
  }
  MainInactiveRateGetAll() {
    this.main911Service.MainGetAll(this.tokenKey, 1, 10, " ", " ", " ", 'TaxName', '0').subscribe(
      data => {
        if (data['main'].length > 0)
          this.totalInactive911Main = data['main'][0].totalRecordCount;
        else this.totalInactive911Main = 0;
      },
      error => { }
    );
  }
  GetAllFund() {
    this.trfaFundMaster.GetAllFund(this.tokenKey, 1, 10, " ", " ", " ", 'FundName', "").subscribe(
      data => {
        if (data['fund'].length > 0)
          this.totalTrfaFund = data['fund'][0].totalRecordCount;
        else this.totalTrfaFund = 0;
      },
      error => { }
    );
  }
  GetAllUpdate() {
    this.trfaUpdateMaster.GetAllUpdate(this.tokenKey, 1, 10, " ", " ", 'Note').subscribe(
      data => {
        if (data['update'].length > 0)
          this.totalTrfaUpdate = data['update'][0].totalRecordCount;
        else this.totalTrfaUpdate = 0;
      },
      error => { }
    );
  }
  ContactsGetAll() {
    this.main911Service.ContactsGetAll(this.tokenKey, 1, 10, ' ', ' ', ' ', ' ', ' ', 'JurisdLevel', '', '', '', '', '').subscribe(
      data => {
        if (data['main'].length > 0)
          this.totalContacts911Main = data['main'][0].totalRecordCount;
        else this.totalContacts911Main = 0;
      },
      error => { }
    );
  }
  GetContent() {

    this.trfaContentMaster.GetAllContent(this.tokenKey, 1, 10, ' ', ' ', ' ', ' ', '', 'RuleText', '').subscribe(
      data => {
        if (data['content'].length > 0)
          this.totalContentTrfa = data['content'][0].totalRecordCount;
        else this.totalContentTrfa = 0;
      },
      error => { }
    );
  }
  GetAllLink() {
    this.linkTypeService.GetAllLink(this.tokenKey, 1, 10, ' ', ' ', 'LinkTypeName').subscribe(
      data => {
        if (data['link'].length > 0)
          this.totalLinkTrfa = data['link'][0].totalRecordCount;
        else this.totalLinkTrfa = 0;
      },
    );
  }

  getAllSource() {
    this.trfaSourceMaster.GetAllSource(this.tokenKey, 1, 10, ' ', 'SourceName').subscribe(
      data => {
        if (data['source'].length > 0)
          this.totalSource = data['source'][0].totalRecordCount;
        else this.totalSource = 0;
      },
    );
  }

  getAllUpdate() {
    this.updates911.GetAllUpdate(this.tokenKey, 1, 10, '', '', 'UpdateDate desc', this.loginUserId, 0).subscribe(
      data => {
        if (data['update'].length > 0)
          this.total911RecordCount = data['update'][0].totalRecordCount;
        else this.total911RecordCount = 0;
      },
    );
  }

  GetAllTypes() {
    this.i360Service.i360BulletinType(this.tokenKey, 1, 10, ' ', 'SortOrder').subscribe(
      data => {
        if (data['listOfBulletinType'].length > 0)
          this.totalRecordCountofType = data['listOfBulletinType'][0].totalRecordCount;
        else this.totalRecordCountofType = 0;
      },
    );
  }

  GetAllSubTypes() {
    this.i360Service.i360BulletinSubType(this.tokenKey, 1, 10, ' ', 'SortOrder').subscribe(
      data => {
        if (data['listOfBulletinSubType'].length > 0)
          this.totalRecordCountofSubType = data['listOfBulletinSubType'][0].totalRecordCount;
        else this.totalRecordCountofSubType = 0;
      },
    );
  }

  GetTracItCount() {
    this.actionService.GetTracAlertsFindByPages(this.tokenKey, this.filteModel).subscribe(
      data => {
        if (data['tracItAlertList'].length > 0)
          this.totalTracItAlerts = data['tracItAlertList'][0].totalRowCount;
        else this.totalTracItAlerts = 0;
      }
    );
  }


  GetVoipAlertCount() {
    this.trfaAlertServices.GetVoipAlertsFindByPages(this.tokenKey, this.filteModel).subscribe(
      data => {
        if (data['voipAlertList'].length > 0) {
          if (this.productId == ToolEnum.voip)
            this.totalVoipAlerts = data['voipAlertList'][0].totalRowCount;
          else
            this.totalWirelessAlerts = data['voipAlertList'][0].totalRowCount;
        }
        else {
          this.totalVoipAlerts = 0;
          this.totalWirelessAlerts = 0;
        }
      }
    )
  }

  GetBriefingAlertCount() {
    this.trfaAlertServices.GetBriefingAlertsFindByPages(this.tokenKey, this.filteModel).subscribe(
      data => {
        if (data['briefingAlertList'].length > 0)
          this.totalBriefingAlerts = data['briefingAlertList'][0].totalRowCount;
        else this.totalBriefingAlerts = 0;
      }
    )
  }

  GetIprAlertsCount() {
    this.trfaAlertServices.GetIPRAlertsFindByPages(this.tokenKey, this.filteModel).subscribe(
      data => {
        if (data['iprAlertList'].length > 0)
          this.totalIprAlerts = data['iprAlertList'][0].totalRowCount;
        else this.totalIprAlerts = 0;
      }
    )
  }

  GetRMDAlertsCount() {
    this.trfaAlertServices.GetRMDAlertsFindByPages(this.tokenKey, this.filteModel).subscribe(
      data => {
        if (data['rmdAlertList'].length > 0)
          this.totalRMDAlerts = data['rmdAlertList'][0].totalRowCount;
        else this.totalRMDAlerts = 0;
      }
    )
  }

  GetCRRARecordsCountForAllPage() {
    if (this.tokenKey != null) {
      this.crraDashboardService.GetRecordsCountForAllPage(this.tokenKey).subscribe(
        data => {
          this.listofRecordCount = data['recordCount'];
          if (this.listofRecordCount != null) {
            if (this.listofRecordCount.length != 0) {
              this.totalcrrastates = this.listofRecordCount[0]["stateCount"];
              this.totalCRRACarrier = this.listofRecordCount[0]["crraCarrierCount"];
              this.totalCRRACategory = this.listofRecordCount[0]["crraCategoriesCount"];
              this.totalCRRASubCategory = this.listofRecordCount[0]["crraSubCategoriesCount"];
              this.totalCRRADeposite = this.listofRecordCount[0]["depositeCount"];
              this.totalCRRAUpdates = this.listofRecordCount[0]["updatesCount"];
              this.totalCRRAContent = this.listofRecordCount[0]["contentCount"];
              this.totalCRRALink = this.listofRecordCount[0]["linksCount"];
              this.totalCRRAAlerts = this.listofRecordCount[0]["crraAlertCount"];
            }
          }
        },
        error => { }
      );
    }
  }

  GetWirelessVoIPRecordsCountForAllPage(productId) {
    if (this.tokenKey != null) {
      this.updatesService.GetRecordsCountForAllPage(this.tokenKey, this.productId).subscribe(
        data => {
          this.listofRecordCount = data['recordCount'];
          if (this.listofRecordCount != null) {
            if (this.listofRecordCount.length != 0) {
              this.totalstates = this.listofRecordCount[0]["stateCount"];
              this.totalupdates = this.listofRecordCount[0]["updateCount"];
              this.totalAlerts = this.listofRecordCount[0]["alertCount"];
              this.allactivesubscriberscounts = this.listofRecordCount[0]["allactivesubscriberscounts"];
            }
          }
        },
        error => { }
      );
    }
  }

  GetAdminDashboardCount() {
    if (this.tokenKey != null) {
      this.userService.GetRecordsCountForAllPage(this.tokenKey).subscribe(
        data => {
          this.listofRecordCount = data['recordCount'];
          if (this.listofRecordCount != null) {
            if (this.listofRecordCount.length != 0) {
              this.totalCompanies = this.listofRecordCount[0]["companyCount"];
              this.totalusers = this.listofRecordCount[0]["adminCount"];
              this.totalMenus = this.listofRecordCount[0]["menusCount"];
            }
          }
        },
        error => { }
      );
    }
  }

  GetIPRDashboardCount() {
    if (this.tokenKey != null) {
      this.iprupdatesService.GetRecordsCountForAllPage(this.tokenKey).subscribe(
        data => {
          this.listofRecordCount = data['recordCount'];
          if (this.listofRecordCount != null) {
            if (this.listofRecordCount.length != 0) {
              this.totalIprAlerts = this.listofRecordCount[0]["alertCount"];
              this.totalIPRupdates = this.listofRecordCount[0]["updateCount"];
            }
          }
        },
        error => { }
      );
    }
  }

  GetRMDDashboardCount() {
    if (this.tokenKey != null) {
      this.rmdupdatesService.GetRecordsCountForAllPage(this.tokenKey).subscribe(
        data => {
          this.listofRecordCount = data['recordCount'];
          if (this.listofRecordCount != null) {
            if (this.listofRecordCount.length != 0) {
              this.totalRMDAlerts = this.listofRecordCount[0]["alertCount"];
              this.totalRMDupdates = this.listofRecordCount[0]["updateCount"];
            }
          }
        },
        error => { }
      );
    }
  }

  GetView47DashboardCount() {
    this.categoryObligationService.GetView47DashboardCount(this.tokenKey).subscribe(
      data => {
        if (data.status == "Success") {
          this.totalObligationCarrierCount = data['dashboardlst'].carrierCount;
          this.totalObligationCategoryCount = data['dashboardlst'].categoryCount;
          this.totalObligationContentCount = data['dashboardlst'].contentCount;
          this.totalObligationUpdateCount = data['dashboardlst'].updateCount;
          this.totalObligationAuditCount = data['dashboardlst'].auditCount;
          this.totalInternalCount = data['dashboardlst'].internalCount;
        }
        else {
          this.totalObligationUpdateCount = 0;
          this.totalObligationCarrierCount = 0;
          this.totalObligationCategoryCount = 0;
          this.totalObligationContentCount = 0;
          this.totalObligationAuditCount = 0;
          this.totalInternalCount = 0;
        } 
      }
    );
  }
}
