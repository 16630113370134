export class ComplianceReportModel {
  total_Reports: number = 0;
  total_Reports_Filed: number = 0;
  total_Reports_Filed_by_Client: number = 0;
  total_Reports_Not_Sent: number = 0;
  total_Reports_Not_Received: number = 0;
  total_Reports_with_No_Remittance: number = 0;
}

export class ReportFilterModel {
  userName: string;
  specialistId: number;
  startDate: Date;
  endDate: Date;
}

export class BriefingReportModel {
  DisplayDate: string;
  CompanyName: string;
  RecordCount: number = 0;
}

export class BriefingReportFilterModel {
  month: number;
  year: number;
  companyId: number;
  fromDate: any;
  toDate: any;
  stateID: any;
  pageNumber: any;
  briefingTypesId: any;  
}

export class VoIPReportFilterModel {
  fromDate: any;
  toDate: any;
}

export class VoIPUpdatesJurisdictionCountsModel {
  StateName: string;
  TitleCount: number = 0;
}

export class VoipReportFilterModel {
  month: number;
  year: number;
  companyId: number;
  fromDate: any;
  toDate: any;
  stateID: any;
  pageNumber: any;
  briefingTypesId: any;  
}

export class AllActiveSubscribersFilterModel {
  companyId: number;
  stateID: any;
  productId: any;
}


export class voipReports{
  companyName:any;
  companyId:number;
  stateName:any;
  licenceCount:any;
  isDemo:boolean;
  isComp:boolean;
}

export class voipReportFilterModel
{
   month:any;
   year:any;
   companyId:number;
   fromDate:number;
   toDate:number;
   stateID: any;
  pageNumber: any;
}

export class AllActiveCompanyReportFilterModel
{
   companyId:number;
   stateID: any;
   productId: any;
}

export class AllActiveCompany{
  companyName:any;
  companyId:number;
  stateName:any;
  licenceCount:any;
  isDemo:boolean;
  isComp:boolean;
}

export class WirelessFilterModel {
  fromDate: any;
  toDate: any;
}

export class ipcsReportFilterModel
{
   month:any;
   year:any;
   companyId:number;
   fromDate:any;
   toDate:any;
   stateID: any;
   pageNumber: any;
}

export class ipcsReports{
  companyName:any;
  companyId:number;
  stateName:any;
  licenceCount:any;
  isDemo:boolean;
  isComp:boolean;
}

export class IpcsTypeModel {
  ipcsTypeName?:any;
  titleCount?: any;
}

export class IpcsJurisdictionModel {
  stateName?:any;
  titleCount?: any;
}

export class IPCSUpdatesCountsByUpdateCategoryReportsModel {
  updateCategoryName?:any;
  categoryWiseUpdatesCount?: any;
}
