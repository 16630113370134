import { Component, HostListener, OnInit } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { StateMasterService } from 'src/Services/state-master.service';
import { CheckAuth } from 'src/Services/utility.service';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { Title } from '@angular/platform-browser';
import { ToolEnum } from '../../../Models/Common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-states',
  templateUrl: './states.component.html',
  styleUrls: ['./states.component.css']
})
export class StatesComponent implements OnInit {
  stateCode: any;
  tokenKey: string;
  listofState: StateMasterModel[];

  PageNumber: number = 1;
  PageSize: number;
  WhereCondition: string;
  OrderBy: string;
  StateName: string;

  upDowNameCSS: string = '';
  upDowShortNameCSS: string = '';
  upDowActiveCSS: string = '';
  upDowSortCSS: string = '';
  isAscending: boolean = false;
  isRecord: boolean = false;
  isloading: boolean = false;
  SelectedTool: string;
  productId: number = 1;
  totalRecordCount: number = 0;
  isState911: boolean = false;
  stateId:number = 0;
  openMenuIndex: number = -1;

  constructor(private router: Router, private state: StateMasterService, private checkAuth: CheckAuth
    , private titleService: Title, private toastr: ToastrService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.listofState = [];
    this.PageNumber = 1;
    this.PageSize = 999;
    this.WhereCondition = 'null';
    this.OrderBy = 'SortOrder';
    this.StateName = 'null';
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.productId = ToolEnum[this.SelectedTool];
    this.titleService.setTitle('States');
debugger
    if (this.router.url == "/admin/911/states" || this.router.url == "/admin/trfa/states" || this.router.url == "/admin/crra/states") {
      this.isState911 = true;
      this.PageSize = 10;
    }
    else this.isState911 = false;

    if(this.router.url == "/admin/trfa/states" || this.router.url == "/admin/crra/states" || this.router.url == "/admin/911/states"){
      this.titleService.setTitle('Jurisdictions');
    }

    if (this.router.url == "/admin/911/states")
    {
      this.productId=ToolEnum.state911
    }
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {

    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.isloading = true;
    this.GetState();
  }

  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.GetState();
  }

  GetState() {
    if (!this.isState911) {
      this.state.GetStateMasterFindByPaged(this.tokenKey, this.PageNumber, this.PageSize, this.WhereCondition, this.OrderBy, this.StateName, this.productId).subscribe(
        data => {
          this.isloading = false;
          this.listofState = data['state'];
          if (this.listofState != null) {
            if (this.listofState.length == 0)
              this.isRecord = true;
            else
              this.isRecord = false;
          }
          else
            this.isRecord = true;
        },
        error => { this.stateCode = error }
      );

    }
    else {
      if (this.StateName == "null") this.StateName = " ";
      this.state.GetAllStateMaster(this.tokenKey, this.PageNumber, this.PageSize, this.StateName, this.OrderBy,this.productId).subscribe(
        data => {
          this.isloading = false;
          this.listofState = data['state'];
          if (this.listofState != null) {
            if (this.listofState.length == 0)
              this.isRecord = true;
            else {
              this.isRecord = false;
              this.totalRecordCount = this.listofState[0].totalRecordCount;
            }
          }
          else
            this.isRecord = true;
        },
        error => { this.stateCode = error }
      );
    }
  }

  redirecttostatematrix(stateid) {
    let navigation = '/admin/' + this.SelectedTool + '/state/setup/manage/' + stateid;
    this.router.navigate([navigation]);
  }

  redirectToStatePreview(stateid) {
    sessionStorage.setItem('StateSetupManageRedirection', stateid);
    sessionStorage.setItem('isAdminOpen', 'true');
    sessionStorage.setItem('isClickOnState', 'true');
    let navigation = '/admin/' + this.SelectedTool + '/state/matrix/preview/';
    this.router.navigate([navigation]);
  }

  keywordFilters($event) {
    this.PageNumber = 1;
    if ($event.target.value != "" && $event.target.value != null)
      this.StateName = $event.target.value;
    else
      this.StateName = 'null';

    this.GetState();
  }

  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.OrderBy = sortBy + ' DESC';

      if (sortBy === "Name") {
        this.upDowNameCSS = 'fa fa-arrow-down';
        this.upDowShortNameCSS = '';
        this.upDowSortCSS='';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "ShortName") {
        this.upDowNameCSS = '';
        this.upDowShortNameCSS = 'fa fa-arrow-down';
        this.upDowSortCSS='';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "sortOrder") {
        this.upDowNameCSS = '';
        this.upDowShortNameCSS = '';
        this.upDowSortCSS='fa fa-arrow-down';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "IsActive") {
        this.upDowNameCSS = '';
        this.upDowShortNameCSS = '';
        this.upDowSortCSS='';
        this.upDowActiveCSS = 'fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.OrderBy = sortBy;

      if (sortBy === "Name") {
        this.upDowNameCSS = 'fa fa-arrow-up';
        this.upDowShortNameCSS = '';
        this.upDowSortCSS='';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "ShortName") {
        this.upDowNameCSS = '';
        this.upDowShortNameCSS = 'fa fa-arrow-up';
        this.upDowSortCSS='';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "sortOrder") {
        this.upDowNameCSS = '';
        this.upDowShortNameCSS = '';
        this.upDowSortCSS='fa fa-arrow-up';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "IsActive") {
        this.upDowNameCSS = '';
        this.upDowShortNameCSS = '';
        this.upDowSortCSS='';
        this.upDowActiveCSS = 'fa fa-arrow-up';
      }
    }
    this.GetState();
  }

  addState() {
    let navigation = '/admin/' + this.SelectedTool + '/states/manage';
    this.router.navigate([navigation]);
  }

  updateState(stateId) {
    let navigation = '/admin/' + this.SelectedTool + '/states/manage/' + stateId;
    this.router.navigate([navigation]);
  }

  setDeleteData(id) {
    this.stateId = id;
  }

  deleteState(stateId) {
    this.isloading = true;
    this.state.DeleteState(this.tokenKey, stateId).subscribe(
      data => {
        this.isloading = false;
        if (data['status'] == 'Success') {
          if (data['message'] != "")
            this.showMessage(data['message'], 'Warning', 'warning')
          else
            this.showMessage("Deleted successfully", 'Success', 'success')
        }
        this.GetState();
      },
      error => { this.stateCode = error }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

}
