import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { I360Service } from 'src/Services/i360Service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-checklist-type-add-update',
  templateUrl: './checklist-type-add-update.component.html',
  styleUrls: ['./checklist-type-add-update.component.css']
})
export class ChecklistTypeAddUpdateComponent implements OnInit {

  userID: any;
  checklisttypeMasterForm: FormGroup;
  SelectedTool: string;
  tokenKey: any;
  isSubmit: boolean = false;
  isEdit:boolean = false;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  VisiblityType:number = 2;

  constructor(private i360service: I360Service,private _fb: FormBuilder, private checkAuth: CheckAuth, private activedRoute: ActivatedRoute, private router: Router, private toastr: ToastrService,private titleService: Title) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userID = +sessionStorage.getItem('AdminUserId');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Checklist');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() { 
    this.isSubmit = false;
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.checklisttypeMasterForm = this._fb.group({
      checklistId: 0,
      checklistName: ['', Validators.required],
      sortOrder: ['',Validators.required],
      isActive:[false],
      isArchive:[false]
    })
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
          this.GetChecklistTypeById(params['id']);
        }
      }
    });
  }

  GetChecklistTypeById(checklistId: number) {
    this.i360service.GetChecklistTypeById(this.tokenKey, parseInt(checklistId.toString())).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.checklisttypeMasterForm.patchValue({
            checklistId: result['briefing'].checklistId,
            checklistName: result['briefing'].checklistName,
            isActive : result['briefing'].isActive,
            isArchive:result['briefing'].isArchive,
            sortOrder: result['briefing'].sortOrder
          });
        }
      });
  }

  save() {
    this.isSubmit = true;
    let data = this.checklisttypeMasterForm.value;
    if (!this.checklisttypeMasterForm.valid||!data.checklistName.trim())
      return
    let value={
      checklistId:data.checklistId,
      checklistName:data.checklistName.trim(),
      sortOrder:data.sortOrder,
      isActive: data.isActive,
      isArchive:data.isArchive,
      visiblityType:2,
      createdBy:this.userID,

    }
    this.i360service.ChecklistTypeAddUpdate(this.tokenKey, value).subscribe(
      result => {
        if (result['status'] == 'Success') {
          if(result['message'] !=""){
            this.showMessage(result['message'], 'Warning', 'warning')
          }else{

            if (data.checklistId > 0)
              this.showMessage('Checklist Type updated successfully', 'Success', 'success');
            else
              this.showMessage('Checklist Type saved successfully', 'Success', 'success');
            this.router.navigate(['/admin/'+this.SelectedTool+'/checklistType']);
          }
        }
        else {
          this.showMessage(result['status'], 'Error', 'error');
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  cancelChecklistType() {
    let navigation = '/admin/' + this.SelectedTool + '/checklistType';
    this.router.navigate([navigation]);
  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }

  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

}
