import { Component, HostListener, OnInit } from '@angular/core';
import { BriefingMasterModel } from 'src/Models/BriefingMasterModel';
import { Router } from '@angular/router';
import { CheckAuth } from 'src/Services/utility.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { ToolEnum } from 'src/Models/Common';
import { BriefingChecklisteFilterModel, BriefingMasterFilterModel } from 'src/Models/filterModel';
import { I360Service } from 'src/Services/i360Service';
import { StateMasterService } from 'src/Services/state-master.service';
import { ActionItemService } from 'src/Services/actionitem.service';
import * as FileSaver from 'file-saver';
@Component({
  selector: 'app-briefing-checklist',
  templateUrl: './briefing-checklist.component.html',
  styleUrls: ['./briefing-checklist.component.css']
})
export class BriefingchecklistComponent implements OnInit {
  filterModel = new BriefingChecklisteFilterModel();
  currentPage: number = 1;
  totalRecordCount: number = -1;

  listOfBriefing: BriefingMasterModel[];

  tokenKey: string = '';
  SelectedTool: string;

  isAscending: boolean = false

  upDowUpdateDateCSS: string = '';
  upDowTitleCSS: string = '';
  upDowDueDateCSS: string = '';
  upDowIsDoneCSS: string = '';
  upDowCreatedDateCSS: string = '';

  briefingChecklistId: number = 0;
  isloading: boolean;

  isShowprogressSpinner = false;
  loginuserID: number = 0;

   //Briefing Title MasterData List
   dropdownListBriefingTitle = [];
   selectedItemsBriefingTitle = [];
   dropdownSettingsBriefingTitle = {};
   listOfBriefingTitleIds = [];

  openMenuIndex: number = -1

  //Briefing Checklist MasterData dropdown
  dropdownListChecklist = [];
  selectedChecklist = [];
  dropdownSettingsChecklist = {};
  listOfChecklist = [];

  //Briefing Status MasterData List
  dropdownListStatus = [];
  selectedItemsStatus = [];
  dropdownSettingsStatus = {};
  listOfStatusIds = [];

  constructor(
    private stateMasterService: StateMasterService, private i360service: I360Service, private router: Router, private checkAuth: CheckAuth,
    private titleService: Title, private toastr: ToastrService, private actionItemServices: ActionItemService) {
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.titleService.setTitle('Briefing Checklist');
    this.filterModel.pageNumber = '1';
    this.filterModel.pageSize = '10';
    this.filterModel.orderBy = 'ISNULL(BriefingChecklistId,BriefingId) DESC';
    this.filterModel.BriefingId=0;
    this.filterModel.ChecklistId="";
    this.filterModel.IsDone=0;
    this.loginuserID = +sessionStorage.getItem('AdminUserId');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }

  ngOnInit() {
    this.GetBriefingTitleMasterData();
    this.GetBriefingChecklistMasterData();
    this.GetStatusMasterData();
    let BriefingTitleId = sessionStorage.getItem("BriefingTitleId");
    if (BriefingTitleId != '' && BriefingTitleId != null && BriefingTitleId != undefined) {
      this.filterModel.BriefingId = Number(BriefingTitleId);
    }
    this.GetBriefingChecklist();
  }

  GetBriefingChecklist() {
    this.isloading = true;
    this.listOfBriefing = [];
    this.i360service.GetBriefingChecklist(this.tokenKey, this.filterModel).subscribe(
      data => {
        this.isloading = false;
        if (data != null) {
          this.listOfBriefing = data['listOfChecklist'];
          if (this.listOfBriefing != null) {
            if (this.listOfBriefing.length > 0) {
              this.totalRecordCount = this.listOfBriefing[0].totalRecordCount;
            }
            else {
              this.totalRecordCount = 0;
            }
          }
          else {
            this.totalRecordCount = 0;
          }
        }
      },
      error => {
        this.isloading = false;
        console.log(error);
      }
    );
  }

  keywordFilters($event) {
    this.filterModel.pageNumber = '1';
    this.currentPage = 1;
    if ($event.target.value != "" && $event.target.value != null) {
      this.filterModel.filterText = $event.target.value;
    }
    else {
      this.filterModel.filterText = '';
    }
    this.GetBriefingChecklist();
  }

  pageChanged($event: any) {
    this.currentPage = $event.page;
    this.filterModel.pageNumber = parseInt($event.page).toString();
    this.GetBriefingChecklist();
  }

  sort(sortBy: any) {
    this.upDowUpdateDateCSS = '';
    this.upDowTitleCSS = '';
    this.upDowDueDateCSS = '';
    this.upDowCreatedDateCSS = '';
    this.upDowIsDoneCSS='';

    if (!this.isAscending) {
      this.isAscending = true;
      this.filterModel.orderBy = sortBy + ' DESC';

      if (sortBy === "updateDate") {
        this.upDowUpdateDateCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "title") {
        this.upDowTitleCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "dueDate") {
        this.upDowDueDateCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "isDone") {
        this.upDowIsDoneCSS = 'fa fa-arrow-down';
      }
      else if (sortBy === "createdDate") {
        this.upDowCreatedDateCSS = 'fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.filterModel.orderBy = sortBy;
      if (sortBy === "updateDate") {
        this.upDowUpdateDateCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "title") {
        this.upDowTitleCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "dueDate") {
        this.upDowDueDateCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "isDone") {
        this.upDowIsDoneCSS = 'fa fa-arrow-up';
      }
      else if (sortBy === "createdDate") {
        this.upDowCreatedDateCSS = 'fa fa-arrow-up';
      }
    }
    this.GetBriefingChecklist();
  }

  addData() {
    this.router.navigate(['admin/briefingServices/manage-checklist']);
  }

  editData(ids,type) {
    if(type==2){
      sessionStorage.setItem("callType","isDone");
    }
    else{
      sessionStorage.removeItem("callType");
    }

    this.router.navigate(['admin/briefingServices/manage-checklist/' + ids])
  }

  setDeleteData(ids) {
    this.briefingChecklistId = ids;
  }

  deleteRecord(briefingChecklistId) {
    this.i360service.DeleteBriefingChecklist(this.tokenKey, briefingChecklistId).subscribe(
      data => {
        this.briefingChecklistId = 0;
        if (data != null) {
          if (data['status']=='Success') {
            this.GetBriefingChecklist();
            this.toastr.success('Record deleted successfully.', 'Delete')
          }
        }
      },
      error => {

      }
    );
  }

  GetBriefingTitleMasterData(): void {
    this.i360service.GetBriefingTitleMasterData(this.tokenKey).subscribe(
      data => {
        if (data["briefing"] != null && data["status"] != "Failure") {
          for (let iCounter = 0; iCounter < data["briefing"].length; iCounter++) {
            this.dropdownListBriefingTitle.push(
              { "id": data["briefing"][iCounter].briefingId, "itemName": data["briefing"][iCounter].title }
            );
          }
        }
        else {
          this.toastr.error(data["message"]);
        }
        this.dropdownSettingsBriefingTitle = {
          singleSelection: true,
          text: "Select Title(s)",
          enableSearchFilter: true,
          classes: "borderLessTextBoxGreen",
          badgeShowLimit: 2,
          noDataLabel: 'No title available',
          showCheckbox: false,
          enableFilterSelectAll: false
        };

        let BriefingTitleId=sessionStorage.getItem("BriefingTitleId");
        if(BriefingTitleId!='' && BriefingTitleId!=null && BriefingTitleId!=undefined){
          if (this.dropdownListBriefingTitle.length > 0) {
            this.selectedItemsBriefingTitle = [];
            this.listOfBriefingTitleIds = [];
            let titledata = this.dropdownListBriefingTitle.filter(y => y.id == BriefingTitleId)[0];
            this.selectedItemsBriefingTitle.push({ "id": titledata.id, "itemName": titledata.itemName })
            this.listOfBriefingTitleIds.push(titledata.id);
          }

          

          sessionStorage.removeItem("BriefingTitleId")
        }
      }, error => {
        this.toastr.error(error);
      })
  }

  onSelectBriefingTitle($event) {
    this.listOfBriefingTitleIds = this.removeElemetninArray(this.listOfBriefingTitleIds, $event.id)
    this.listOfBriefingTitleIds.push($event.id);
    this.filterModel.BriefingId=Number($event.id);
    this.GetBriefingChecklist();
  }

  onDeSelectBriefingTitle($event) {
    if (this.listOfBriefingTitleIds != null) {
      if (this.listOfBriefingTitleIds.length > 0) {
        this.listOfBriefingTitleIds = this.removeElemetninArray(this.listOfBriefingTitleIds, $event.id)
      }
    }
    this.filterModel.BriefingId=0;
    this.GetBriefingChecklist();
  }

  onSelectAllBriefingTitle($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfBriefingTitleIds != null) {
        if (this.listOfBriefingTitleIds.length > 0) {
          this.listOfBriefingTitleIds = this.removeElemetninArray(this.listOfBriefingTitleIds, $event[iCounter].id)
          this.listOfBriefingTitleIds.push($event[iCounter].id);
        }
        else {
          this.listOfBriefingTitleIds.push($event[iCounter].id);
        }
      }
    }
  }

  onDeSelectAllBriefingTitle($event) {
    this.listOfBriefingTitleIds = [];
    this.filterModel.BriefingId=0;
    this.GetBriefingChecklist();
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }

  toggleMenu(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? -1 : index; // Toggle the menu
  }
  
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.kebab-menu')) {
      this.openMenuIndex = null;
    }
  }

  GetBriefingChecklistMasterData(): void {
    this.i360service.GetBriefingChecklistMasterData(this.tokenKey).subscribe(
      data => {
        if (data["masterData"] != null && data["status"] != "Failure") {
          for (let iCounter = 0; iCounter < data["masterData"].length; iCounter++) {
            this.dropdownListChecklist.push(
              { "id": data["masterData"][iCounter].checklistId, "itemName": data["masterData"][iCounter].checklistName }
            );
          }
        }
        else {
          this.toastr.error(data["message"]);
        }
        this.dropdownSettingsChecklist = {
          singleSelection: false,
          text: "Select Checklist(s)",
          enableSearchFilter: true,
          classes: "borderLessTextBoxGreen",
          badgeShowLimit: 3,
          noDataLabel: 'No checklist available',
          showCheckbox: true
        };
      }, error => {
        this.toastr.error(error);
      })
  }
  
  onSelectChecklist($event) {
    this.listOfChecklist = this.removeElemetninArray(this.listOfChecklist, $event.id)
    this.listOfChecklist.push($event.id);
    this.filterModel.ChecklistId=this.listOfChecklist.toString();
    this.GetBriefingChecklist();
  }

  onDeSelectChecklist($event) {
    if (this.listOfChecklist != null) {
      if (this.listOfChecklist.length > 0) {
        this.listOfChecklist = this.removeElemetninArray(this.listOfChecklist, $event.id)
      }
      this.filterModel.ChecklistId=this.listOfChecklist.toString();
      this.GetBriefingChecklist();
    }
  }

  onSelectAllChecklist($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfChecklist != null) {
        if (this.listOfChecklist.length > 0) {
          this.listOfChecklist = this.removeElemetninArray(this.listOfChecklist, $event[iCounter].id)
          this.listOfChecklist.push($event[iCounter].id);
        }
        else {
          this.listOfChecklist.push($event[iCounter].id);
        }
      }
    }
    this.filterModel.ChecklistId=this.listOfChecklist.toString();
    this.GetBriefingChecklist();
  }

  onDeSelectAllChecklist($event) {
    this.listOfChecklist = [];
    this.filterModel.ChecklistId="";
    this.GetBriefingChecklist();
  }


  GetStatusMasterData(): void {
    this.dropdownListStatus.push(
      { "id": 1, "itemName": "✔ Done" },
      { "id": 2, "itemName": "! Needs Review" }
    );

    this.dropdownSettingsStatus = {
      singleSelection: true,
      text: "Select Status(es)",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No status available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };
  }

  onSelectStatus($event) {
    this.listOfStatusIds = this.removeElemetninArray(this.listOfStatusIds, $event.id)
    this.listOfStatusIds.push($event.id);
    this.filterModel.IsDone=Number($event.id);
    this.GetBriefingChecklist();
  }

  onDeSelectStatus($event) {
    if (this.listOfStatusIds != null) {
      if (this.listOfStatusIds.length > 0) {
        this.listOfStatusIds = this.removeElemetninArray(this.listOfStatusIds, $event.id)
      }
    }
    this.filterModel.IsDone=0;
    this.GetBriefingChecklist();
  }

  onSelectAllStatus($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfStatusIds != null) {
        if (this.listOfStatusIds.length > 0) {
          this.listOfStatusIds = this.removeElemetninArray(this.listOfStatusIds, $event[iCounter].id)
          this.listOfStatusIds.push($event[iCounter].id);
        }
        else {
          this.listOfStatusIds.push($event[iCounter].id);
        }
      }
    }
  }

  onDeSelectAllStatus($event) {
    this.listOfStatusIds = [];
    this.filterModel.IsDone=0;
    this.GetBriefingChecklist();
  }
}
